import React, { useEffect, useCallback } from 'react'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { useSession } from '@emerald-works/react-auth'
import { useNavigate } from 'react-router-dom'
import {
  AppToolbar,
  PageAlert,
  PasswordDialog,
  AccountGeneralTabComponent,
  AccountSubscriptionTabComponent,
  AccountUpgradeBanner,
  AccountTeamBanner,
  BillingHistoryTable,
  MailtoLink,
  TeamPaymentLoading
} from '../../components'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice, userSlice } from '../../reducers'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'

const TabPanel = props => {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`account-tabpanel-${index}`}
      aria-labelledby={`account-tab-${index}`}
      {...other}
      data-test={`account-table-${index}`}
    >
      {value === index && <span>{children}</span>}
    </div>
  )
}

const AccountPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const session = useSession()
  const { user } = session
  const [isPasswordDialogOpen, setPasswordDialogOpen] = React.useState(false)
  const [showUserAlert, setShowUserAlert] = React.useState(false)
  const [showPasswordAlert, setShowPasswordAlert] = React.useState(false)
  const [tabValue, setTabValue] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [isRedirecting, setRedirecting] = React.useState(false)

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)

  const userData = useSelector(userSlice.selectors.selectUserData)
  const invoices = useSelector(userSlice.selectors.selectInvoices)
  const invoicePDF = useSelector(userSlice.selectors.selectInvoicePDF)
  // const hasTeam = userData?.teams?.length || false
  const isPremium = userData?.premium === 'team' || false
  const isNewSeason = userData?.reviewNewSeason || false
  const [getInvoicesInfo] = useEvent([userSlice.eventBus.getInvoicesInfo])
  const [getInvoicePdf] = useEvent([userSlice.eventBus.getInvoicePDF])

  const openPDF = useCallback(invoicePDFUrl => {
    window.open(invoicePDFUrl)
    dispatch(userSlice.actions.clearInvoicePDF())
  }, [dispatch])

  useEventsOnViewLoad(() => {
    if (isPremium && !invoices) {
      getInvoicesInfo.trigger()
      setIsLoading(true)
    }

    if (Object.keys(userData).length === 0) {
      setIsLoading(true)
    }
  }, [getInvoicesInfo])

  useEffect(() => {
    if (invoices && invoices.length) {
      setIsLoading(false)
    }
  }, [invoices])

  useEffect(() => {
    if (isPremium && !invoices) {
      getInvoicesInfo.trigger()
    }
  }, [getInvoicesInfo, isPremium, invoices])

  useEffect(() => {
    if (invoicePDF && invoicePDF.length) {
      setIsDownloading(false)
      openPDF(invoicePDF)
    }
  }, [invoicePDF, openPDF])

  const { register, watch, reset } = useForm({
    defaultValues: {
      code: '',
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  })

  const handleTabChange = (_, newValue) => {
    setTabValue(newValue)
  }

  const handleDialogClose = () => {
    setPasswordDialogOpen(false)
  }

  const handleDialogOpen = () => {
    setPasswordDialogOpen(true)
  }

  const onCloseUserAlert = () => {
    setShowUserAlert(false)
  }

  const onClosePasswordAlert = () => {
    setShowPasswordAlert(false)
  }

  const handleInvoiceClick = invoiceId => {
    getInvoicePdf.trigger(invoiceId)
    setIsDownloading(true)
  }

  const handleUpgradeClick = () => {
    dispatch(userSlice.actions.setMenuLevel(0))
    dispatch(userSlice.actions.setActiveMenuSection('teams'))
    navigate('/upgrade')
  }

  const buildUrlParams = (name, email) => {
    const [firstName, lastName] = name.split(' ')
    if (lastName) {
      return `first_name=${firstName}&last_name=${lastName}&email=${email}`
    }
    return `first_name=${firstName}&email=${email}`
  }

  const handleRedirect = React.useCallback(
    () => {
      const { attributes } = user
      const { name, email } = attributes
      const urlParams = buildUrlParams(name, email)
      window.location.href = `${process.env.REACT_APP_RECURLY_URL}?${urlParams}`
    }, [user])

  const clickUpgrade = () => {
    setRedirecting(true)
    const timer = setTimeout(() => {
      handleRedirect()
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      {isRedirecting && (
        <TeamPaymentLoading />
      )}
      {!isRedirecting && (
        <Grid container>

          <div className={isLGScreen ? classes.main : classes.mainMD} id='main'>
            {showUserAlert && (
              <PageAlert
                onClose={onCloseUserAlert}
                data-test='alert-userChanged'
                text='Success! Your details have been updated.'
              />
            )}
            {showPasswordAlert && (
              <PageAlert
                onClose={onClosePasswordAlert}
                data-test='alert-passwordChanged'
                text='Password changed succesfully!'
              />
            )}
            <Grid container spacing={3}>
              <Grid item lg={12}>
                <Typography
                  variant='h1'
                  data-test='accountSettingsHeader'
                >
                  Account settings
                </Typography>
              </Grid>
              <Grid item lg={12}>
                <Tabs value={tabValue} onChange={handleTabChange} aria-label='Account settings tabs'>
                  <Tab className={tabValue === 0 ? classes.tabSelected : classes.tab} label='General settings' />
                  <Tab className={tabValue === 1 ? classes.tabSelected : classes.tab} label='Subscription' />
                </Tabs>
                <hr className={classes.hr} />
                {
                  isLoading
                    ? (
                      <Skeleton variant='rounded' animation='wave' width='100%'>
                        <AccountGeneralTabComponent />
                      </Skeleton>
                      )
                    : (
                      <Grid container spacing={3}>
                        <Grid item lg={tabValue === 0 ? 6 : 4} md={12}>
                          <TabPanel value={tabValue} index={0}>
                            <AccountGeneralTabComponent
                              handleDialogOpen={handleDialogOpen}
                              setShowUserAlert={setShowUserAlert}
                            />
                          </TabPanel>
                          <TabPanel value={tabValue} index={1}>
                            <AccountSubscriptionTabComponent
                              isPremium={isPremium}
                            />
                          </TabPanel>
                        </Grid>
                        {!isPremium && tabValue === 0 && <Grid item lg={2} />}
                        {!isPremium && tabValue === 1 && <Grid item lg={4} />}
                        {isPremium && tabValue === 1 && <Grid item lg={2} />}
                        {isPremium && isNewSeason && (
                          <Grid item lg={6} md={12}>
                            <AccountTeamBanner handleClick={clickUpgrade} />
                          </Grid>
                        )}
                        {!isPremium && (
                          <Grid item lg={4} md={12}>
                            <AccountUpgradeBanner handleClick={handleUpgradeClick} />
                          </Grid>
                        )}
                        {isPremium && tabValue === 1 && (
                          <Grid item xs={12}>
                            <Typography style={{ marginTop: '64px', marginBottom: '24px' }} variant='h3'>Billing history</Typography>
                            <BillingHistoryTable
                              invoices={invoices}
                              handleInvoiceClick={handleInvoiceClick}
                              isDownloading={isDownloading}
                            />
                          </Grid>
                        )}
                        {isPremium && tabValue === 1 && (
                          <Grid item xs={12}>
                            <Typography style={{ marginTop: '64px', marginBottom: '8px' }} variant='h3'>Changing the admin</Typography>
                            <Typography style={{ marginBottom: '8px' }} variant='body1'>If you would like to change the admin of your Teams account, no problem - just email us at <MailtoLink email='lpbenchmark@mindtools.com' /></Typography>
                            <Typography style={{ marginBottom: '8px' }} variant='body1'>By changing the admin, your admin access will revert to team member permissions. You will still be part of the team, unless the new admin removes you.</Typography>
                          </Grid>
                        )}
                      </Grid>
                      )
                }
              </Grid>
            </Grid>
          </div>
          <PasswordDialog
            open={isPasswordDialogOpen}
            handleClose={handleDialogClose}
            formRegister={register}
            formWatch={watch}
            formReset={reset}
            showPasswordAlert={setShowPasswordAlert}
            data-test='modal-changePassword'
          />
        </Grid>
      )}
      {/* </Grid> */}
    </div>
  )
}

export default AccountPage
