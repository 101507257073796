import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'
Font.register({ family: 'Matter', src: Matter })
const styles = StyleSheet.create({
  card: {
    width: '100%',
    height: '100%',
    border: '1px solid #E9EAEB',
    borderRadius: '5px',
    padding: '5px',
    marginVertical: 5
  },
  behavioursContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  behaviourCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '10px',
    marginVertical: 5,
    border: '1px solid #E9EAEB',
    borderRadius: '5px',
    textAlign: 'center'
  }

})
export default styles
