import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import { CircularChart, KeyQuestions, KeyBehaviours, PDFFooter } from '../index'
// import styles from './style'
import globalStyles from '../../style'
const KeyComponents = ({ title, introText, component, keyQuestions, keyBehaviours, score, pageNumber, season }) => {
  const dataURL = localStorage.getItem(`${component}ChartData`)
  return (
    <View style={globalStyles.pageView}>
      <View>
        {/* title/intro */}
        <Text style={globalStyles.loudText}>Exploring the six key components</Text>
        <Text style={globalStyles.heading}>{title}</Text>
        <Text style={globalStyles.introText}>{introText}</Text>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
        {/* circular chart and key questions */}
        <View style={{ width: '15%', display: 'flex', justifyContent: 'center', marginLeft: 40 }}>
          <CircularChart score={score} />

        </View>
        <View style={{ width: '85%' }}>

          <KeyQuestions questions={keyQuestions} />
        </View>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {/* line chart and behaviours */}
        <View style={{ width: '50%' }}>
          <Text style={[globalStyles.subheading, { marginBottom: 0 }]}>Overall participant trends</Text>
          {!dataURL
            ? <Text style={globalStyles.subheading}>Graph placeholder</Text>
            : <Image src={dataURL} style={{ objectFit: 'contain', alignSelf: 'center' }} />}
        </View>
        <View style={{ width: '50%' }}>
          <KeyBehaviours behaviours={keyBehaviours} season={season} />
        </View>
      </View>
      <PDFFooter pageNumber={pageNumber} />
    </View>
  )
}
export default KeyComponents
