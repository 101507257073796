import { Paper, Typography, Button } from '@material-ui/core'
import { useStyles } from './style'

import React from 'react'

const TeamGettingStartedCard = ({ step, header, description, handleGetStarted }) => {
  const classes = useStyles()

  return (
    <Paper className={classes.homePaper}>
      <Typography variant='h2' className={handleGetStarted ? classes.cardStepFilled : classes.cardStep}>
        {step}
      </Typography>
      <Typography variant='h3' className={classes.cardTitle}>
        {header}
      </Typography>
      <span style={{ flex: 1 }}>
        {description.map((text, idx) => (
          <Typography key={idx} className={classes.cardDescription}>
            {text}
          </Typography>
        ))}
      </span>
      {handleGetStarted && (
        <div className={classes.footer}>
          <Button
            data-test='lnk-team-create'
            variant='contained'
            color='secondary'
            onClick={handleGetStarted}
            className='Team_Get_Started'
          >
            Get started
          </Button>
        </div>
      )}
    </Paper>
  )
}

export default TeamGettingStartedCard
