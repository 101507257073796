import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  text: {
    fontSize: '1.3rem',
    width: '36rem'
  },
  subtext: {
    marginTop: '1.2rem',
    marginBottom: 40,
    fontSize: '1.2rem'
  },
  buttons: {
    display: 'flex',
    alignSelf: 'start'
  },
  spacing: {
    marginLeft: 16
  }
}))
