import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    // transition: '0.1s all ease',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignContent: 'flex-end',
    '& button:focus-visible': {
      border: '1px solid #006FB7',
      boxShadow: '0px 2px 10px 5px rgba(0,111,183,0.2)'
    }

  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '400px',
    padding: 2,
    margin: 2,
    marginTop: '6px',
    transform: 'scale(1.1)'
  },
  spaced: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    width: '400px',
    gap: '210px',
    '& p': {
      whiteSpace: 'nowrap',
      fontFamily: 'Matter',
      fontSize: '12px'
    }

  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  button: {
    border: '1px solid rgba(37, 55, 70, 1)',
    borderRadius: 0,
    marginLeft: '4px',
    marginRight: '4px',
    height: '32px',
    width: '32px',
    fontFamily: 'Matter',
    fontSize: '12px',
    fontWeight: 400,
    color: '#253746',
    transition: '0.1s ease background',
    '&:hover': {
      background: 'rgba(0, 111, 183, 0.2)',
      border: '3px solid #006FB7'
    }

  },
  selected: {
    border: '1px solid #006FB7',
    borderRadius: 0,
    color: '#fff',
    fontWeight: 700,
    background: '#006FB7',
    '&:hover': {
      background: '#006FB7'
    }

  }

}))
