import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  imageGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10rem',
    paddingRight: '5rem'
  }
}))
