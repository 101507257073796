import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 24px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0 0'
  },
  text: {
    marginTop: '24px'
  },
  listText: {
    fontSize: '16px',
    marginTop: '5px'
  },
  rightButton: {
    marginLeft: 16
  }
}))
