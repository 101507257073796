import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  chartBox: {
    width: '100%',
    minHeight: '295px'
  },
  paperRoot: {
    paddingTop: '24px',
    paddingBottom: '4px',
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  paperIcon: {
    width: '25px !important',
    height: '25px !important'

  },
  paperDivider: {
    margin: '16px 0'
  },
  paperButton: {
    justifyContent: 'flex-start',
    fontSize: '14px',
    fontWeight: 700,
    margin: '4px 0',
    height: '35px',
    whiteSpace: 'nowrap',
    // '& span .MuiButton-startIcon': {
    //   marginLeft: 'calc(4vw - 60px)'
    // }
    '& .MuiButton-label': {
      '@media (min-width:900px) and (max-width: 1008px)': {
        whiteSpace: 'pre-wrap',
        justifyContent: 'space-between'
      }
    }
  },

  paperComponents: {
    margin: '20px 0'
  },
  arrowIcon: {
    fontSize: '14px !important'
  }
}))
