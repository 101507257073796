import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    alignItems: 'center',
    padding: 20
  },
  image: {
    cursor: 'pointer',
    alignSelf: 'center',
    objectFit: 'contain'
  },
  title: {
    cursor: 'pointer',
    '& p': {
      fontSize: '0.75rem',
      margin: '0'
    }
  }
}))
