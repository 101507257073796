import React from 'react'
import { Text, View, Image } from '@react-pdf/renderer'
import styles from './style'
import globalStyles from '../../style'
import FormalLearning from '../../img/maturity-formal-learning.png'
import Governance from '../../img/maturity-governance.png'
import LearningFlow from '../../img/maturity-learning-flow.png'
import RoleIndividuals from '../../img/maturity-role-individuals.png'
import RoleLD from '../../img/maturity-role-ld.png'
import RoleLeaders from '../../img/maturity-role-leaders.png'
const insights = [
  {
    title: 'Governance',
    descriptions: [
      'Right now, your strategic planning is reactive, not proactive. Your L&D activity tends to meet only your employees’ immediate training needs. You’re not currently taking measures to future-proof your organisation. And your governing decisions don’t tend to be driven by data or evidence-based principles.',
      'Your department has good business awareness. Sometimes it makes decisions according to business needs—but even then, these decisions are often made in isolation, and lack a cohesive strategic approach. You also aren’t taking full advantage of data.',
      'The department puts outcomes first. Learning programmes are designed around what’s needed for the organisation to reach its goals. You may be using data and research to determine whether a programme produced desirable results.',
      'Research and data analytics are leveraged to fine-tune learning programmes for optimal delivery and design. What’s more, senior stakeholders are actively involved in identifying where—and how—the organisation would benefit from learning. This means these learning programmes can be designed in precise strategic alignment with business goals.'
    ],
    tips: [
      'Work with senior management to articulate what KPIs and business metrics would be best to measure learning impact. This will facilitate more strategic decisions, because your metrics can guide and inform future behaviours.',
      'Your next target should be adopting an outcomes-first approach to L&D. Your primary concern should be identifying ways in which your organisation can benefit from learning, in both the short and long term. This knowledge can then be used as a platform for learning programme design.',
      'Business leaders are involved in deciding where the organisation would benefit from learning—but now they need to do more. Your outcomes-first approach is good, but would prove most beneficial when aligned with the priorities of your organisation’s senior figures. Research and data analytics could be further leveraged to fine-tune learning programmes for optimal delivery and design.',
      'Maintain and nurture relationships with stakeholders. Keep line managers and supervisors up to date about how learning programmes are performing. And of course, there are always ways to keep on top of your data analysis skills and techniques!'
    ],
    imgLink: Governance
  },
  {
    title: 'Formal learning',
    descriptions: [
      'Led my demand, your learning programmes may be focused primarily on compliance training, and other transactional interventions. Your L&D department might not be particularly technologically advanced when it comes to developing, targeting, and delivering programmes.',
      'You may be applying technology when delivering learning, as well as experimenting with combinations of technologies and face-to-face learning practices. Your learners enjoy some freedom and flexibility in how and when they learn.',
      'Your L&D department may be focused on applying formal learning to employees. Learning might involve activities that encourage people to practice applying their newly acquired knowledge. Multiple stakeholders are considered in the design of learning programmes.',
      'Your department focuses on long-term behavioural change. Your learning programmes are designed around influencing behaviour to improve performance, and monitor the success of that goal.'
    ],
    tips: [
      'Improve learning flexibility by offering a wider range of courses, and utilise technology in the delivery of those courses. It may be best to blend face-to-face delivery with eLearning, rather than shift to exclusively digital delivery methods.',
      'Your L&D department tends to focus on understanding how employees can apply their learning. But it’s time to also consider what skills people could develop that would have real impact on day-to-day work, and how these skills would connect more broadly to overall business goals. You may also find it beneficial to focus on incorporating more stakeholder perspectives into your programme designs.',
      'Top-performing departments are far more likely to focus on long-term behavioural change. So consider designing your learning programmes around influencing behaviour—and monitoring and measuring the success of this goal.',
      'Keep challenging learners, motivating them to develop their skills, and internalising what they’ve learned. Continue developing formal learning courses in response to the evolution of the company. As business strategy shifts, so the course content should grow and transform in response.'
    ],
    imgLink: FormalLearning
  },
  {
    title: 'Learning in the flow of work',
    descriptions: [
      'You may be neglecting peer-to-peer learning and communities of practice. Both the department and the organisation itself have only a limited understanding of the benefits of improving accessibility of learning materials.',
      'A great tool at this stage is the marketing-style campaign. This doesn’t just increase participation, but also boosts employee awareness of your organisation’s L&D services. But there may also be security concerns, which are limiting how widely learning resources can be distributed.',
      'Formal learning courses are considered only one option amongst many for the delivery of learning. Your focus right now is supporting peer-to-peer interactions and flexible learning, with a view to supporting performance across the organisation.',
      'Top-performing departments are far more likely to focus on long-term behavioural change. So consider designing your learning programmes around influencing behaviour—and monitoring and measuring the success of this goal.'
    ],
    tips: [
      'Become more proactive in understanding what people need to learn in order to carry out their roles more proficiently. Engage your learners in one-to-one dialogues to explore their own requirements. You could also conduct more rigorous research, be that in the form of surveys, interviews, or focus groups.',
      'Encourage and facilitate learning external to formal training courses. When developing learning programmes, opting for a social, more casual approach will facilitate embedding learning within your organisation, thereby laying the foundations for a workforce that is continually developing and growing.',
      'Top-performing departments utilise a lot of digital technology to facilitate knowledge sharing through employee peer networks. For example, they take advantage of webinars that are open to the whole organisation, and in so doing stimulate discussion and more leftfield thinking. You might also prioritise developing your department’s capability of enabling collaborative learning.',
      'Develop your department’s capability of facilitating collaborative peer-to-peer learning. This is critical to the continual development of an organisation-wide culture of learning.'
    ],
    imgLink: LearningFlow
  },
  {
    title: 'Role of L&D',
    descriptions: [
      'Your organisation lacks the knowledge and capabilities to realise the benefits of learning innovation and digitalisation.',
      'At the moment there’s only limited confidence in embracing technology—and limited capability of doing so. You often see the beginnings of investment in improving the skills of the L&D team itself, as opposed to focusing solely on the skills of the rest of the organisation.',
      'Learning leaders are business-savvy enablers, and they regularly consult stakeholders to explore how L&D can further boost performance in their respective areas of the company.',
      'L&D is a foundation for innovation and performance. Employees and managers alike rarely consider learning to be an interference, because its benefits are obvious, and align seamlessly with business goals.'
    ],
    tips: [
      'Audit in-house skills, and compare them to the highest-priority capabilities for your organisation’s advancement. Draw on experts and internal departments, and create a holistic perspective on how your learning team can support performance.',
      'Build confidence as enablers of business. This means consolidating experience with stakeholders, and reflecting on how to pay this knowledge forward as you forge your journey to becoming internal consultants at your organisation.',
      'In its optimal manifestation, L&D is a foundation on which to build innovation and performance. In such an environment, employees and managers alike would rarely consider learning to be an interference; rather, its benefits would be obvious and attractive. In a culture as healthy as this, learning outcomes would be both immediately practical, and aligned with long-term strategic business goals.',
      'Continue to incorporate expertise within the business as a learning resource unto itself. Consult in-house talent when designing learning programmes. Encourage peer-to-peer learning, as this will stimulate the permeation of knowledge and skills throughout the entire organisation.'
    ],
    imgLink: RoleLD
  },
  {
    title: 'Role of individuals',
    descriptions: [
      'Learners are thought of only as recipients. Their potential value as contributors hasn’t been recognised.',
      'You may be underestimating learners’ capacity to manage their own learning.',
      'Individual learners genuinely have a voice. Their insights and professional opinions influence programme design, and are further bolstered by learner networks.',
      'Learners’ perspectives are fully integrated into your L&D services. By understanding how employees acquire and share knowledge, learning programmes can be designed to leverage the natural resource of social learning. The keystone of any high-impact learning culture is a legion of passionate, proactive learners.'
    ],
    tips: [
      'People learn most effectively when they’re granted the agency to engage with materials and resources as they see fit, and in a way that suits them. So ideate ways of involving learners in the initial design stage of your L&D strategy.',
      'Give learners a platform from which they can express their views about how learning programmes are being delivered. Establish a support network for learners, as this will lay the foundations for building an organisation-wide culture of learning.',
      'Learners’ perspectives are fully integrated into your L&D services. By understanding how employees acquire and share knowledge, learning programmes can be designed to leverage the natural resource of social learning. The keystone of any high-impact learning culture is a legion of passionate, proactive learners.',
      'Encourage the growth of learning networks across the organisation. Enable people to establish individual development plans.'
    ],
    imgLink: RoleIndividuals
  },
  {
    title: 'Role of leaders',
    descriptions: [
      'Managers and senior executives need to embrace their roles and get far more involved. Your L&D approach certainly has the seed of potential—but without affirmation from the top, it probably won’t ever flower.',
      'Managers are starting to contribute their ideas to the design of learning programmes. But L&D departments still struggle to influence organisational culture.',
      'Managers and business leaders are committed to on-the-job learning. They both participate in and advocate for collaborative learning amongst peers.',
      'Managers encourage employees to share and contribute their ideas in a non-judgemental environment. And at the core of this culture of innovation is a platform that motivates people to experiment, and think outside the box.'
    ],
    tips: [
      'The department needs to reimagine how it conducts its dialogue with key stakeholders when communicating the benefits of its learning strategy. Show them the immense potential of your L&D projections, and iterate precisely how they could be instrumental in propelling that vision into reality.',
      'Managers and business leaders need to show their commitment to on-the-job learning. Departments could benefit immensely from facilitating organisation-wide discussions promoting the value of continual peer-to-peer learning.',
      'Managers encourage employees to share and contribute their ideas in a non-judgemental environment. Leaders encourage staff to learn from their mistakes. And at the core of this culture of innovation is a platform that motivates people to experiment, and think outside the box.',
      'Managers encourage staff to learn from—and not fear—their mistakes. This galvanises an organisation-wide ethos of blue-sky thinking.'
    ],
    imgLink: RoleLeaders
  }
]
const InsightsCard = ({ title, stage }) => {
  const requiredInsight = insights.filter(insight => insight.title === title)
  const description = requiredInsight[0].descriptions[stage - 1]
  const progressText = requiredInsight[0].tips[stage - 1]
  const imageLink = requiredInsight[0].imgLink
  return (
    <View style={styles.card}>
      <View style={styles.imageBox}>
        <Image src={imageLink} style={{ display: 'block', width: 30, height: 30, objectFit: 'contain' }} />
      </View>
      <View style={styles.infoTextBox}>
        <Text style={[globalStyles.subheading, { marginBottom: 10, marginTop: 0 }]}>{title}</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>{description}</Text>
      </View>
      <View style={styles.progressTextBox}>
        <Text style={[globalStyles.body, globalStyles.bold]}>{stage < 4 ? `How to progress to stage ${stage + 1}:` : 'Where to focus next:'}</Text>
        <Text style={[globalStyles.body, { fontSize: 11 }]}>{progressText}</Text>
      </View>
    </View>
  )
}
export default InsightsCard
