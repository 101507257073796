import React from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { MailtoLink } from '../../components'
import { useNavigate } from 'react-router-dom'
import { getCountry } from './util'
import { useStyles } from './style'

const LPTeamRow = () => {
  const classes = useStyles()
  const navigate = useNavigate()

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const country = getCountry(timezone)
  let countryValue
  switch (country) {
    case 'SJ':
    case 'NL':
    case 'AD':
    case 'GR':
    case 'RS':
    case 'DE':
    case 'SK':
    case 'BE':
    case 'RO':
    case 'HU':
    case 'MD':
    case 'DK':
    case 'IE':
    case 'GI':
    case 'FI':
    case 'TR':
    case 'PT':
    case 'SI':
    case 'LU':
    case 'ES':
    case 'MT':
    case 'AX':
    case 'BY':
    case 'MC':
    case 'NO':
    case 'FR':
    case 'ME':
    case 'CZ':
    case 'LV':
    case 'IT':
    case 'SM':
    case 'BA':
    case 'MK':
    case 'BG':
    case 'SE':
    case 'EE':
    case 'AL':
    case 'UA':
    case 'LI':
    case 'VA':
    case 'AT':
    case 'LT':
    case 'PL':
    case 'HR':
    case 'CH':
      countryValue = '€3,950'
      break
    case 'AU':
      countryValue = 'AU$6,950'
      break
    case 'CA':
      countryValue = 'CA$5,950'
      break
    case 'US':
      countryValue = '$4,950'
      break
    case 'IN':
      countryValue = '₹3,600'
      break
    default:
      countryValue = '£3,500'
      break
  }

  const handleIndividual = () => {
    navigate('/register?type=individual')
  }

  const handleTeam = () => {
    navigate('/register?type=teams')
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={3} className={classes.blueContainer}>
        <Grid item xs={12}>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: 40, marginTop: 64, color: 'white' }}>Complete solo or as a team</Typography>
        </Grid>
        <Grid item md={6}>
          <Typography variant='h3' style={{ color: 'white', marginBottom: 8 }}>Complete individually for free</Typography>
          <Typography style={{ color: 'white', marginBottom: 24 }}>Whether you work on your own or as part of a small, big, local or global team, you can complete the benchmark individually for free.</Typography>
          <Typography style={{ color: 'white' }}>Simply create an account, complete the review, and receive instant results to help you create or improve your L&D strategy.</Typography>
          <Typography style={{ color: 'white', fontSize: 16, fontWeight: 700, textTransform: 'uppercase', margin: '24px 0' }}>Free</Typography>
          <Button disableElevation variant='contained' className={classes.whiteBtn} onClick={handleIndividual}>Create individual account</Button>
        </Grid>
        <Grid item md={6}>
          <Typography variant='h3' style={{ color: 'white', marginBottom: 8 }}>Benchmark with your colleagues</Typography>
          <Typography style={{ color: 'white', marginBottom: 24 }}>Introduce your team to new ideas and ways of thinking. Discover differences in opinion, then start conversations that drive change.</Typography>
          <Typography style={{ color: 'white', fontSize: 16, fontWeight: 700, margin: '24px 0 16px' }}>Everything the individual LPB offers, plus:</Typography>
          <div className={classes.tickMark}>
            <img src='/img/check_24px.png' style={{ marginTop: 6 }} alt='checkmark' width='18' height='12' />
            <Typography style={{ color: 'white', marginLeft: 19 }}>1 admin and up to 19 team members under one account</Typography>
          </div>
          <div className={classes.tickMark}>
            <img src='/img/check_24px.png' style={{ marginTop: 6 }} alt='checkmark' width='18' height='12' />
            <Typography style={{ color: 'white', marginLeft: 19 }}>Combined average learning maturity score for the whole team</Typography>
          </div>
          <div className={classes.tickMark}>
            <img src='/img/check_24px.png' style={{ marginTop: 6 }} alt='checkmark' width='18' height='12' />
            <Typography style={{ color: 'white', marginLeft: 19 }}>See anonymised colleagues’ scores simultaneously</Typography>
          </div>
          <div className={classes.tickMark}>
            <img src='/img/check_24px.png' style={{ marginTop: 6 }} alt='checkmark' width='18' height='12' />
            <Typography style={{ color: 'white', marginLeft: 19 }}>Aggregated team results you can share with business stakeholders</Typography>
          </div>
          <Typography style={{ color: 'white', marginBottom: 24 }}><Typography style={{ color: 'white', fontSize: 16, fontWeight: 700, display: 'inline' }}>{countryValue}</Typography> one-off payment</Typography>
          <Button disableElevation variant='contained' className={classes.whiteBtn} onClick={handleTeam}>Get a team account</Button>
          <Typography style={{ color: 'white', fontSize: 12, marginTop: 15 }}>NOTE: you will be redirected to a secure external payment system. Alternatively, if you require an invoice to pay, email <MailtoLink email='lpbenchmark@mindtools.com' light>lpbenchmark@mindtools.com</MailtoLink></Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default LPTeamRow
