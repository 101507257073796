import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '34px',
    overflow: 'hidden'
  },
  smallCell: {
    width: '12.5%'
  },
  scorePositive: {
    fill: '#7BC340 !important'
  },
  scoreNegative: {
    fill: '#F44336 !important'
  },
  scoreBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: '5px 0'
  }
}))
