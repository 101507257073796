import React from 'react'
import {
  Button,
  Typography
} from '@material-ui/core'
import { useStyles } from './style'
import { surveySlice } from '../../../../reducers'
import { useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
const ResultsForm = () => {
  const [devUpdateResults] = useEvent([surveySlice.eventBus.devUpdateResults])
  const userResults = useSelector(surveySlice.selectors.selectResults)
  const latestResults = useSelector(surveySlice.selectors.selectLatestResults)
  const surveyResponses = useSelector(surveySlice.selectors.selectResponses)
  const hasPreviousResults = latestResults && latestResults.season === 2022
  const testUserNotStarted = surveyResponses && Object.keys(surveyResponses).length === 0
  const testUserCompleted = userResults && userResults.score
  const testUserNewSeason = hasPreviousResults
  const getUserState = () => {
    const pageState = testUserNotStarted && !testUserNewSeason
      ? 'new'
      : testUserNewSeason && !testUserCompleted
        ? 'new-season'
        : testUserCompleted
          ? 'recurrent'
          : 'partial'
    return pageState
  }
  const userState = getUserState()
  const classes = useStyles()

  const handleCompleterClick = () => {
    devUpdateResults.trigger({ desiredUserType: 'completer' })
  }

  const handlePartialClick = () => {
    devUpdateResults.trigger({ desiredUserType: 'partial' })
  }

  const handleNewClick = () => {
    devUpdateResults.trigger({ desiredUserType: 'new' })
  }

  return (
    <>
      <Typography
        variant='h3'
      >
        Current user state: {userState}
      </Typography>

      <div className={classes.buttonsContainer}>
        <Button
          className={classes.primaryButton}
          type='submit'
          variant='contained'
          data-test='btn-makePartial'
          onClick={handlePartialClick}
        >
          Make me a partial user
        </Button>
        <Button
          className={classes.primaryButton}
          type='submit'
          variant='contained'
          data-test='btn-makeCompleter'
          onClick={handleCompleterClick}
        >
          Make me a completer user
        </Button>
        <Button
          className={classes.primaryButton}
          type='submit'
          variant='contained'
          data-test='btn-makeNew'
          onClick={handleNewClick}
        >
          Make me a new user
        </Button>
      </div>
    </>
  )
}

export default ResultsForm
