import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  chartBox: {
    width: '100%',
    height: '222px',
    marginBottom: '20px'
  },
  bigChartBox: {
    width: '100%'
  },
  paperRoot: {
    padding: '24px'
  },
  paperList: {
    paddingInlineStart: '20px !important',
    marginBlockStart: '0 !important'
  },
  textBox: {
    marginTop: '12px'
  },
  introText: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  points: {
    marginLeft: '-22px'
  },
  stageHeading: {
    marginTop: 0,
    marginBottom: 0
  }
}))
