import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'
import MatterBold from '../../../../fonts/Matter-Bold.ttf'
Font.register({ family: 'Matter', src: Matter })
Font.register({ family: 'MatterBold', src: MatterBold })
const styles = StyleSheet.create({

  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '60%'
  },
  heading: {
    color: 'black',
    fontSize: 36,
    marginBottom: 20,
    fontFamily: 'MatterBold'
  },
  subheading: {
    color: 'black',
    fontSize: 18,
    marginTop: 20,
    marginBottom: 20,
    fontFamily: 'MatterBold'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '80%'
  },
  body: {
    fontSize: 10,
    fontFamily: 'Matter'
  },
  imageContainer: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  img: {
    objectFit: 'contain'
  },
  logosBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '15%',
    width: '100%',
    marginTop: -30,
    marginBottom: 10
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '15%',
    height: '100%',
    objectFit: 'contain',
    marginHorizontal: 5
  },
  logo: {
    // display: 'block',
    // width: '100px',
    // minWidth: '100px',
    // height: '50px',
    // minHeight: '50px',

    objectFit: 'contain'
  }
})
export default styles
