import reducer, * as actions from './slice'
import * as selectors from './selectors'
import name from './name'
// import * as gammaRay from './gamma-ray'
//
const login = {
  reducer,
  actions,
  selectors,
  name
}

export default login
