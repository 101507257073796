import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '0 24px'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '24px 0 0'
  },
  rightButton: {
    marginLeft: 16
  },
  header: {
    marginBottom: 24
  },
  text: {
    marginBottom: 16
  }
}))
