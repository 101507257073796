import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'
// import styles from './style'
const EngagePage = ({ scores, behaviours, season }) => {
  const questions = ['Have you identified the stakeholders you need to work with to deliver your learning strategy?', 'How can you more effectively connect with these people to put your strategy into practice?']
  const title = 'Organisational engagement'
  const introText = 'Managing change successfully and engaging key stakeholders is critical to success. Through engagement, employees feel more empowered, and are more committed to your organisation’s L&D vision, regardless of whether they’re learners, facilitators or leaders. That’s why organisational engagement is a key indicator of a high-impact learning culture.'
  const userScore = scores[0].values[0].score
  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <KeyComponents score={userScore} pageNumber={9} title={title} introText={introText} component='engage' keyQuestions={questions} keyBehaviours={behaviours} season={season} />
    </Page>
  )
}
export default EngagePage
