import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'

import styles from './style'
import globalStyles from '../../style'
const StagePage1 = () => {
  const dataURL = localStorage.getItem('stagesChartData')

  return (
    <Page size='A4' orientation='landscape'>
      <View style={globalStyles.pageView}>
        <View style={styles.textContainer}>
          <Text style={globalStyles.heading}>Learning maturity stage</Text>
          <Text style={globalStyles.introText}>Your maturity score sits within one stage of our 4-phase maturity model. This model shows the transformation of an L&D team and its impact on your organisation. Each stage of the journey delivers more impact and reduces friction.</Text>
          <Text style={[globalStyles.introText, { marginTop: '10px' }]}>By grouping organisations in this way, we can analyse what top performers (those at Stage 4) are doing differently, and offer insights into the transformation journey.</Text>
        </View>
        <View style={styles.graphContainer}>
          {dataURL && <Image src={dataURL} />}
        </View>

        <PDFFooter pageNumber={11} />
      </View>

    </Page>
  )
}
export default StagePage1
