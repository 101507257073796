import React from 'react'
import { Skeleton } from '@material-ui/lab'
import { useStyles } from './style'

const SurveySkeleton = ({ rows }) => {
  const classes = useStyles()
  const rowsMap = Array.from(Array(rows), (x, i) => i)
  return (
    <span>
      {rowsMap.map((_, i) => {
        return i > 0
          ? (
            <span>
              <Skeleton className={classes.skeleton} variant='text' animation='wave' width='50%' height='40px' />
              <Skeleton className={classes.skeleton} variant='rect' animation='wave' height='120px' style={{ marginBottom: '40px' }} />
            </span>
            )
          : (
            <span>
              <Skeleton className={classes.skeleton} variant='text' animation='wave' width='50%' height='40px' />
              <Skeleton className={classes.skeleton} variant='rect' animation='wave' height='120px' style={{ marginBottom: '40px' }} />
            </span>
            )
      })}
    </span>
  )
}

export default SurveySkeleton
