import React, { useEffect } from 'react'
import {
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Tooltip
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useForm, Controller } from 'react-hook-form'
import { useStyles } from './style'
import Auth from '@aws-amplify/auth'
import {
  enableRegisterSend,
  validateEmail,
  validatePassword
} from '../../libs/formUtils'
import { useDispatch } from 'react-redux'
import { registerSlice, loginSlice } from '../../reducers'
import { LPBLink } from '../../components'
import { basedURL } from '../../helpers/domain'

const RegisterAccountForm = ({ teamId, userEmail, handleBack, type }) => {
  const classes = useStyles()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const dispatcher = useDispatch()
  const { register, handleSubmit, watch, control } = useForm({
    defaultValues: {
      name: '',
      password: '',
      email: userEmail?.replace('3D', '') || '',
      experience: '',
      premium: false
    }
  })

  const currentName = watch('name')
  const currentEmail = watch('email')
  const currentPassword = watch('password')
  const currentExperience = watch('experience')
  const currentPremium = watch('premium')

  const isDisabled = enableRegisterSend(
    currentName,
    currentEmail,
    currentPassword,
    currentExperience,
    currentPremium
  )

  const nameError = {
    error: !isDisabled && !currentName.length,
    text: !isDisabled && !currentName.length && 'Must provide a name'
  }

  const emailError = {
    error: !isDisabled && !validateEmail(currentEmail),
    text:
      !isDisabled &&
      !validateEmail(currentEmail) &&
      'Must be a valid email address'
  }

  const passwordError = {
    error: !isDisabled && !validatePassword(currentPassword)
  }

  const [isRegistering, setIsRegistering] = React.useState(false)
  const [showPassword, setShowPassword] = React.useState(false)
  const [registerFeedback, setRegisterFeedback] = React.useState({})
  const [isPremiumChecked, setPremiumChecked] = React.useState(false)
  const [isEmailPrePopulated, setEmailPrePopulated] = React.useState(false)

  const handleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const handleCheck = () => {
    setPremiumChecked(prev => !prev)
  }

  const onSubmit = async data => {
    setIsRegistering(true)
    try {
      const { name, email: username, password, premium } = data
      await Auth.signUp({
        username,
        password,
        attributes: {
          name
        },
        clientMetadata: {
          // window.location.protocol + '//' + basedURL() + '/verify'
          verifyUrl: `${window.location.protocol}//${basedURL()}/verify`,
          premium: type === 'teams' ? 'true' : 'false',
          teamId: teamId.length ? teamId : null
        }
      })
      dispatcher(loginSlice.actions.setShowRegisterAlert(true))
      dispatcher(
        registerSlice.actions.setRegisterData({ username, isRegistered: true, isPremium: premium })
      )
      hj('stateChange', '/lpb-signup')
      dataLayer.push({
        event: 'stateChange',
        attributes: {
          path: '/lpb-signup'
        }
      })
    } catch (e) {
      console.log('Signup error:', e)
      setRegisterFeedback({ error: true, message: e.message })
    }
    setIsRegistering(false)
  }

  useEffect(() => {
    if (userEmail !== undefined && userEmail !== '') {
      setEmailPrePopulated(true)
      window.history.replaceState(null, '', `${window.location.protocol}//${basedURL()}/register`)
    } else {
      setEmailPrePopulated(false)
    }
  }, [userEmail])

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Button
          className={classes.button}
          startIcon={<ArrowBackIcon />}
          onClick={handleBack}
          data-test='btn-backToSummary'
        >
          back
        </Button>
        <Typography data-test='authPageHeader' variant='h1'>
          {type === 'teams' ? 'Sign up for a teams account' : 'Sign up'}
        </Typography>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='nameField'
              label='Full name'
              variant='outlined'
              type='text'
              name='name'
              inputRef={register({ required: true })}
              inputProps={{ 'aria-label': 'Full name' }}
              required
              error={nameError.error}
              helperText={nameError.text}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='emailField'
              label='Organisation email'
              variant='outlined'
              name='email'
              type='text'
              inputRef={register({
                required: true,
                // eslint-disable-next-line no-useless-escape
                pattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
              inputProps={{ 'aria-label': 'Organisation email', readOnly: isEmailPrePopulated }}
              required
              error={emailError.error || registerFeedback.error}
              helperText={emailError.text || registerFeedback.message}
            />
          </FormControl>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              label='Password'
              type={showPassword ? 'text' : 'password'}
              variant='outlined'
              name='password'
              data-test='passwordField'
              required
              error={passwordError.error}
              helperText='8+ characters, with a mix of lower and upper case letters, numbers and symbols.'
              // eslint-disable-next-line no-useless-escape
              inputRef={register({
                required: true,
                pattern: /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?=.*\W).{8,20}/
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='Show password'
                      data-test='showPassword'
                      tabIndex='-1'
                      onClick={handleShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ 'aria-label': 'Password' }}
            />
          </FormControl>
          {false && (
            <FormControl className={`${classes.formInput} ${classes.checkboxContainer}`}>
              <FormControlLabel
                name='premium'
                control={
                  <Checkbox
                    name='premium'
                    checked={isPremiumChecked}
                    onChange={handleCheck}
                    inputRef={register({
                      required: false
                    })}
                  />
                }
                label='Sign up for a premium account'
              />
              <Tooltip className={classes.tooltip} title='Sign up for a premium account to receieve access to the full functionality of the Learning Performance Benchmark. You will be redirected to our payment portal after verifying your account.' aria-label='Sign up for a premium account to receieve access to the full functionality of the Learning Performance Benchmark. You will be redirected to our payment portal after verifying your account.'>
                <img src='/img/info.png' alt='info' width='20' height='20' />
              </Tooltip>
            </FormControl>
          )}
          {false && (
            <FormControl
              // data-test='lnd-years'
              variant='outlined'
              fullWidth
              className={classes.formInput}
            >
              <InputLabel id='lnd-years'>
                How many years have you been working in an L&D role?
              </InputLabel>
              <Controller
                control={control}
                name='experience'
                render={({ onChange, value, ref }) => (
                  <Select
                    data-test='lnd-years'
                    labelId='lnd-years'
                    label='How many years have you been working in an L&D role?'
                    inputRef={ref}
                    onChange={onChange}
                    value={value}
                  >
                    <MenuItem data-test='lnd-years1' value='1'>
                      0-3 years
                    </MenuItem>
                    <MenuItem data-test='lnd-years2' value='2'>
                      4-7 years
                    </MenuItem>
                    <MenuItem data-test='lnd-years3' value='3'>
                      8-11 years
                    </MenuItem>
                    <MenuItem data-test='lnd-years4' value='4'>
                      12+ years
                    </MenuItem>
                    <MenuItem data-test='lnd-years0' value='0'>
                      Prefer not to say
                    </MenuItem>
                  </Select>
                )}
              />

            </FormControl>
          )}
          <p className={classes.termsText}>
            By creating an account, you agree to Emerald Works’{' '}
            <LPBLink to='/privacy-policy'>privacy policy</LPBLink> and{' '}
            <LPBLink to='/terms-and-conditions'>terms and conditions</LPBLink>.
          </p>
          {
            !isRegistering
              ? (
                <Button
                  id='btn-create-account-register'
                  aria-label='Register button'
                  className={classes.primaryButton}
                  data-test='btn-create'
                  type='submit'
                  variant='contained'
                  disabled={isDisabled}
                >
                  {type === 'teams' ? 'Create account' : 'Create free account'}
                </Button>
                )
              : (
                <CircularProgress />
                )
          }
          <p className={classes.loginText}>
            Already registered?{' '}
            <LPBLink data-test='lnk-registerPageLogin' to='/login'>
              Log in
            </LPBLink>.
          </p>
        </form>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default RegisterAccountForm
