import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    padding: '32px 0',
    boxShadow: '-90px 0px 0px 0px #006FB7,90px 0px 0px 0px #006FB7'
  },
  cardRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '24px',
    flexDirection: 'column',
    height: '100%',
    textAlign: 'center'
  },
  question: {
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: 1.167,
    '@media (min-width:600px)': {
      fontSize: '1.2853rem'
    }

  }
}))
