import { CustomSlider } from '../../../../components/survey-slider'
import * as Survey from 'survey-react'
const CustomSliderWidget = {
  name: 'customslider',
  title: 'Custom Slider',
  iconName: '',
  htmlTemplate: '<div></div>',
  widgetIsLoaded: function () {
    return true
  },
  isFit: function (question) {
    return question.getType() === 'customslider'
  },
  init: function (question, element) {},
  activatedByChanged: function (activatedBy) {
    // we do not need to check acticatedBy parameter, since we will use our widget for customType only
    // We are creating a new class and derived it from text question type. It means that text model (properties and fuctions) will be available to us
    Survey.JsonObject.metaData.addClass('customslider', [], null, 'text')
    // custom properties for the slider range. nomenclature follows from 'nouislider' custom widget currently used.
    Survey.JsonObject.metaData.addProperties('customslider', [
      { name: 'rangeMin', default: 0 },
      { name: 'rangeMax', default: 100 },
      { name: 'rangeStart', default: 0 },
      { name: 'percent', default: true }
    ])
  },
  isDefaultRender: false,
  render: function (question) {
    return (
      <CustomSlider
        min={question.rangeMin}
        max={question.rangeMax}
        start={question.value || question.rangeStart}
        percent={question.percent}
      />
    )
  },

  afterRender: function (question, element) {
    question.rangeStart = question.value
    const inputBox = Array.from(element.getElementsByTagName('input'))
    const inputObserver = new MutationObserver(function (mutation) {
      question.value = mutation[0].target.value
    })

    inputBox.forEach(input => {
      input.onchange = () => {
        question.value = input.value
      }
      inputObserver.observe(input, { subtree: true, childList: true, attributes: true })
    })
    const onValueChangedCallback = function () {
      element.style.overflow = 'hidden'
    }
    question.valueChangedCallback = onValueChangedCallback
    onValueChangedCallback()
  }
}
export default CustomSliderWidget
