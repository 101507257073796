import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  primaryButton: {
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  secondaryButton: {
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  imageGrid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '10rem',
    paddingRight: '5rem'
  }
}))
