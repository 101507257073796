import React from 'react'
import { useStyles } from './style'
import {
  Typography,
  Stepper,
  Step,
  Grid,
  StepLabel,
  StepContent,
  Avatar
} from '@material-ui/core'
import { dashboardLinks, surveySteps } from '../navigation-drawer/mocks'
import ArrowDropUp from '@material-ui/icons/ArrowDropUpRounded'
import ArrowDropDown from '@material-ui/icons/ArrowDropDownRounded'
import { useNavigate } from 'react-router-dom'
import ErrorOutline from '@material-ui/icons/ErrorOutline'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'

const StepperIcon = ({ active, error, completed, idx }) => {
  const classes = useStyles()
  return (
    <div className={active ? classes.activeIcon : classes.icon}>
      {error
        ? (
          <ErrorOutline
            className={active ? classes.errorIconActive : classes.errorIcon}
          />
          )
        : completed
          ? (
            <CheckCircleOutline
              className={
                active ? classes.completedIconActive : classes.completedIcon
              }
            />
            )
          : (
            <Avatar
              style={{ width: 24, height: 24 }}
              fontSize='small'
              className={
                active
                  ? `${classes.numberIconActive} ${classes.overline}`
                  : `${classes.numberIcon} ${classes.overline}`
              }
            >
              {idx + 1}
            </Avatar>
            )}
    </div>
  )
}

const YouMenu = ({
  page,
  activeSurveyStep,
  activeInnerLink,
  activeSurveyInnerStep,
  sectionProgress,
  activeDashboardLink,
  setActiveDashboardLink,
  setActiveSurveyInnerStep,
  setActiveSurveyStep,
  closeNavBar,
  isLGScreen,
  setActiveInnerLink,
  resultsPage,
  percentage
}) => {
  const navigate = useNavigate()
  const classes = useStyles()
  const handleStepClick = (stepIndex) => {
    setActiveSurveyStep(stepIndex)
    if (!isLGScreen) {
      closeNavBar()
    }
  }
  const checkEnter = (e) => e.key === 'Enter'
  const handleInnerStepClick = (innerStep) => {
    setActiveSurveyInnerStep(innerStep)
    if (!isLGScreen) {
      closeNavBar()
    }
  }
  const handleDashboarLink = (link) => {
    if (!resultsPage) {
      navigate('/dashboard')
    } else {
      setActiveDashboardLink(link)
      if (!isLGScreen) {
        closeNavBar()
      }
    }
  }
  const handleDashboardInnerClick = (link) => {
    setActiveInnerLink(link)
    if (!isLGScreen) {
      closeNavBar()
    }
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        {page === 'review'
          ? (
            <>
              <Typography className={`${classes.overline} ${classes.overlineMargin}`}>
                Your Review
              </Typography>
              <Stepper
                nonLinear
                activeStep={activeSurveyStep}
                orientation='vertical'
                className={classes.stepperRoot}
              >
                {surveySteps.map((step, idx) => (
                  <Step key={idx}>
                    <StepLabel
                      error={
                        sectionProgress[step.label]
                          ? sectionProgress[step.label].error
                          : null
                      }
                      // classes={{ root: classes.activeStepIcon }}
                      completed={
                        sectionProgress[step.label]
                          ? sectionProgress[step.label].completed
                          : null
                      }
                      StepIconComponent={StepperIcon}
                      StepIconProps={{
                        active: activeSurveyStep === idx,
                        error: sectionProgress[step.label]
                          ? sectionProgress[step.label].error
                          : null,
                        completed: sectionProgress[step.label]
                          ? sectionProgress[step.label].completed
                          : null,
                        idx: idx
                      }}
                    >
                      <Grid container className={classes.stepLabelContainer}>
                        <Grid item xs={12} className={classes.stepLabelLeftGrid}>
                          <Typography
                            tabindex={0}
                            onClick={() => handleStepClick(step.position)}
                            onKeyDown={(e) =>
                              checkEnter(e)
                                ? handleStepClick(step.position)
                                : null}
                            className={
                              activeSurveyStep === idx
                                ? classes.activeStepLabel
                                : sectionProgress[step.label] &&
                                  sectionProgress[step.label].completed
                                  ? classes.completeStepLabel
                                  : classes.stepLabel
                            }
                            variant='subtitle2'
                            data-test={`nav-surveyStep-${step.position}`}
                          >
                            {step.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </StepLabel>
                    {step.innerSteps.length && (
                      <StepContent>
                        {step.innerSteps.map(({ title, name }, idx) => (
                          <Typography
                            tabindex={0}
                            onClick={() => handleInnerStepClick(name)}
                            onKeyDown={(e) =>
                              checkEnter(e) ? handleInnerStepClick(name) : null}
                            className={
                              activeSurveyInnerStep.startsWith(name)
                                ? classes.activeInnerStepLabel
                                : classes.innerStepLabel
                            }
                            key={idx}
                            data-test={`nav-innerStep-${step.position}-${idx}`}
                          >
                            {title}
                          </Typography>
                        ))}
                      </StepContent>
                    )}
                  </Step>
                ))}
              </Stepper>
            </>
            )
          : (
            <>
              <div className={classes.linksContainer}>
                <Typography
                  className={`${classes.overline} ${classes.overlineMargin}`}
                >
                  Your Results
                </Typography>
                {dashboardLinks.map((link, idx) => (
                  <span key={idx} data-test={'nav-' + link.type}>
                    <Typography
                      key={idx}
                      onClick={() => handleDashboarLink(link.type)}
                      onKeyDown={(e) =>
                        checkEnter(e) ? handleDashboarLink(link.type) : null}
                      variant='body2'
                      className={
                        activeDashboardLink === link.type
                          ? `${classes.linksTextActive} ${classes.activeShadow}`
                          : classes.linksText
                      }
                      tabindex={0}
                    >
                      {link.text}
                      {link.innerLinks &&
                        link.innerLinks.length &&
                        (activeDashboardLink === link.type
                          ? (
                            <ArrowDropUp className={classes.dropdownArrow} />
                            )
                          : (
                            <ArrowDropDown className={classes.dropdownArrow} />
                            ))}
                    </Typography>
                    {activeDashboardLink === link.type &&
                      link.innerLinks &&
                      link.innerLinks.length && (
                        <div className={classes.innerLinksContainer}>
                          {link.innerLinks.map((innerLink, idx) => (
                            <Typography
                              key={idx}
                              onClick={() =>
                                handleDashboardInnerClick(innerLink.type)}
                              onKeyDown={(e) =>
                                checkEnter(e)
                                  ? handleDashboardInnerClick(innerLink.type)
                                  : null}
                              variant='body2'
                              className={
                                activeInnerLink === innerLink.type
                                  ? classes.innerLinksTextActive
                                  : classes.innerLinksText
                              }
                              data-test={'nav-' + innerLink.type}
                              tabindex={0}
                            >
                              {innerLink.text}
                            </Typography>
                          ))}
                        </div>
                    )}
                  </span>
                ))}
              </div>
            </>
            )}
      </div>
    </div>
  )
}
export default YouMenu
