import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'
// import styles from './style'
const EmployeePage = ({ scores, behaviours, season }) => {
  const questions = ['Do your people have a voice when it comes to adapting your organisation’s learning strategy?', 'Does your L&D consider their motivations, and allow for autonomy and choice?']
  const title = 'Employee experience'
  const introText = 'Employee experience is a key tenet of any L&D strategy. It’s underpinned by 2 key indicators of a high-impact learning culture: 1) choice: empowering employees to make their own decisions fosters a culture in which development is self-led; and 2) motivation: to discover what makes employees tick, leaders must explore their behaviours, practices and everyday experiences.'
  const userScore = scores[0].values[0].score
  return (
    <Page size='A4' orientation='landscape' wrap={false}>
      <KeyComponents score={userScore} title={title} introText={introText} component='employee' keyQuestions={questions} keyBehaviours={behaviours} pageNumber={6} season={season} />
    </Page>
  )
}
export default EmployeePage
