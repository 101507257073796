import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  cardsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '85%'
  }
})
export default styles
