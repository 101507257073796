import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../fonts/Matter-Regular.ttf'
import MatterBold from '../../fonts/Matter-Bold.ttf'
Font.register({ family: 'Matter', src: Matter })
Font.register({ family: 'MatterBold', src: MatterBold })
Font.registerHyphenationCallback(word => (
  [word]
))
const globalStyles = StyleSheet.create({
  pageView: {
    marginLeft: 30,
    marginRight: 30,
    marginTop: 25,
    marginBottom: 25,
    width: '93%',
    height: '95%',
    alignSelf: 'center'
  },
  heading: {
    color: '#253746',
    fontSize: 36,
    marginBottom: 20,
    fontFamily: 'MatterBold'
  },
  subheading: {
    color: '#253746',
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
    fontFamily: 'MatterBold'
  },
  body: {
    color: '#253746',
    fontSize: 12,
    fontFamily: 'Matter'
  },
  introText: {
    color: '#253746',
    fontSize: 13,
    fontFamily: 'Matter'
  },
  loudText: {
    color: '#253746',
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily: 'MatterBold'
  },
  bold: {
    color: '#253746',
    fontFamily: 'MatterBold'
  }

})
export default globalStyles
