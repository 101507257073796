import React, { useMemo } from 'react'
import * as Survey from 'survey-react'
import 'survey-react/modern.css'

import { CustomSliderWidget, CustomRatingWidget, CustomTooltipWidget } from './widgets'

Survey.StylesManager.applyTheme('modern')
Survey.CustomWidgetCollection.Instance.addCustomWidget(CustomSliderWidget, 'customtype')
Survey.CustomWidgetCollection.Instance.addCustomWidget(CustomRatingWidget, 'customtype')
Survey.CustomWidgetCollection.Instance.addCustomWidget(CustomTooltipWidget, 'customtype')

const getCurrency = params => {
  const region = params[0]
  if (region === 'UK') return 'Pound Sterling'
  if (region === 'Other European country') return 'Euro'
  if (region === 'Australia/New Zealand') return 'AUS Dollar'
  if (['USA/Canada', 'Other Asia Pacific', 'Middle East/Africa/India', 'South/Central America'].indexOf(region) > -1) return 'US Dollar'
}

Survey.FunctionFactory.Instance.register('getCurrency', getCurrency)
Survey.Serializer.addProperty('question', 'customMatrixTop:text')
Survey.Serializer.addProperty('question', 'hideColumnHeaders:text')
Survey.Serializer.addProperty('question', 'validatePercentage:text')

const SurveyComponent = ({ json, onValueChanged, data, saveSurveyResponses, unanswered }) => {
  const surveyJson = {
    ...json,
    calculatedValues: [
      {
        name: 'currency',
        expression: 'getCurrency({Region})',
        includeIntoResult: true
      }
    ]
  }

  const surveyModel = new Survey.Model(surveyJson)

  function validateSum (inputs, warning) {
    inputs.forEach(input => {
      input.min = '0'
      input.onkeydown = function (e) {
        if (!((e.keyCode > 95 && e.keyCode < 106) ||
          (e.keyCode > 47 && e.keyCode < 58) ||
          e.keyCode === 8)) {
          return false
        }
      }
    })
    const values = inputs.map(input => Number(input.value))
    if (values.some(value => value > 0) && values.reduce((a, b) => a + b) !== 100) {
      warning.textContent = 'The numbers do not equal 100%. Please ensure that the percentages sum to 100%.'
    }
    if (values.reduce((a, b) => a + b) === 100 || !values.some(value => value > 0)) {
      warning.textContent = ''
    }
  }

  surveyModel.onAfterRenderPage.add(function (survey, options) {
    const questionsToStyle = ['Driver', 'Staff', 'Benefit']
    const firstQuestionName = Object.entries(survey.questionHashes.names)[0][0].split('_')[0]
    if (questionsToStyle.includes(firstQuestionName)) {
      const rows = options.htmlElement.querySelectorAll('.sv-row')
      const rowsArr = Array.from(rows)
      for (let i = 0; i < rowsArr.length; i++) {
        rowsArr[i].style = 'padding-bottom: 1em;'
      }
    }
  })

  surveyModel.onAfterRenderQuestion.add(function (survey, options) {
    // required profile questions
    if (unanswered && unanswered.includes(options.htmlElement.getAttribute('name'))) {
      options.htmlElement.classList.add('sv-unanswered')
    }
    // custom rating
    if (options.question.getType() === 'customrating') {
      const main = document.getElementById('main')
      main.classList.add('adjust-rating-padding')
      const questionTitles = options.htmlElement.querySelectorAll('.sv-question__header--location--left')
      const arr = Array.from(questionTitles)
      arr.forEach(question => question.classList.add('sv-title__customrating'))
    }
    // validate percentage
    if (options.question.validatePercentage) {
      const warning = document.createElement('p')
      warning.textContent = ''
      warning.style = 'font-weight: 400;color:  #B00020;padding-left: 7px;font-family: Matter;margin-left: 50%;'
      const questionContent = Array.from(options.htmlElement.querySelectorAll('.sv-question__content'))[0]
      questionContent.appendChild(warning)

      const inputs = Array.from(options.htmlElement.querySelectorAll('input'))
      inputs.forEach((input, idx) => {
        input.onchange = (e) => {
          validateSum(inputs, warning)
        }
      })

      validateSum(inputs, warning)
    }
    // only for single matrix questions rendered to look like one big matrix question
    // top question has new property customMatrixTop: true
    // subsequent questions have property hideColumnHeaders: true
    if (!options.question.hideColumnHeaders && !options.question.customMatrixTop) return
    // hide headers
    if (options.question.hideColumnHeaders) {
      const headers = Array.from(options.htmlElement.querySelectorAll('thead'))
      headers.forEach(header => header.remove())
    }

    // check index of question in survey page
    const questions = survey.questionHashes.names
    const questionsArray = [...Object.keys(questions)]

    const currentQuestionIndex = questionsArray.indexOf(options.question.propertyHash.name)
    // style according to odd/even
    if (currentQuestionIndex % 2 === 0 || currentQuestionIndex === 0) {
      const row = Array.from(options.htmlElement.querySelectorAll('td'))
      row.forEach(el => {
        if (el.classList.contains('sv-table__cell')) {
          el.style = 'background: #EEEFF1 !important'
        }
      })
    } else {
      const row = Array.from(options.htmlElement.querySelectorAll('td'))
      row.forEach(el => {
        if (el.classList.contains('sv-table__cell')) {
          el.style = 'padding-bottom: 0px;padding-top: 0px'
        }
      })
    }
  })

  const surveyJS = useMemo(() => (
    <Survey.Survey
      model={surveyModel}
      onValueChanged={onValueChanged}
      data={data}
      showNavigationButtons={false}
    />
  ),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [saveSurveyResponses, data, json]
  )

  return surveyJS
}

export default SurveyComponent
