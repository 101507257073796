import React from 'react'
import { Page, View } from '@react-pdf/renderer'
import { PDFFooter, InsightsCard } from '../../components'
import styles from './style'
import globalStyles from '../../style'
const insights = [
  'Learning in the flow of work',
  'Role of L&D',
  'Role of individuals',
  'Role of leaders'
]
const InsightsPage2 = ({ stage }) => {
  return (
    <Page size='A4' orientation='landscape'>
      <View style={globalStyles.pageView}>
        <View style={styles.cardsContainer}>

          {insights.map((insight, index) => (
            <InsightsCard key={index} stage={stage} title={insight} />
          ))}

        </View>
        <PDFFooter pageNumber={14} />
      </View>
    </Page>
  )
}
export default InsightsPage2
