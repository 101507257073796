import React from 'react'
import { MailtoLink } from '../../components'
import { Typography, LinearProgress } from '@material-ui/core'
import { useStyles } from './style'

const PaymentConfirmationComponent = () => {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <Typography variant='h1' className={classes.loadText}>
        We're just confirming your payment. It won't take long...
      </Typography>
      <LinearProgress color='secondary' className={classes.progress} />
      <Typography variant='body1' className={classes.loadFooter}>
        © 2022 Emerald Works Limited. All rights reserved. Having trouble? Email
        us at{' '}
        <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>
          lpbenchmark@mindtools.com
        </MailtoLink>
      </Typography>
    </div>
  )
}

export default PaymentConfirmationComponent
