import React from 'react'
import { Page } from '@react-pdf/renderer'
import { KeyComponents } from '../../components'
// import styles from './style'
const TeamPage = ({ scores, behaviours, season }) => {
  const questions = ['Does your team have the capacity to enable learning in the flow of work?', 'Is evidence-based practice embedded in how your organisation makes decisions about learning and performance support?']
  const title = 'L&D team capabilities'
  const introText = 'Internal L&D capabilities, learning design, learning transfer, performance support and collaboration are key indicators of a high- impact learning culture. An L&D strategy that lacks the right team capabilities to actually execute it will always fail. What skills do you consider a priority for your L&D professional to ensure a modernised learning strategy?'
  const userScore = scores[0].values[0].score
  return (
    <Page size='A4' orientation='landscape'>
      <KeyComponents score={userScore} pageNumber={8} title={title} introText={introText} component='enable' keyQuestions={questions} keyBehaviours={behaviours} season={season} />
    </Page>
  )
}
export default TeamPage
