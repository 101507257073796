import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { surveySlice, userSlice } from '../../reducers'
import { useSession } from '@emerald-works/react-auth'
import { AppToolbar, NavigationDrawer, TeamAlignedComponent, WhyUpgradeComponent, TeamUpgradeHowItWorks, TeamFAQ, TeamPaymentLoading, PaymentConfirmationComponent, PageAlert } from '../../components'
import { Grid, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { useStyles } from './style'

const ContactPage = () => {
  const classes = useStyles()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const dispatch = useDispatch()
  const session = useSession()
  const navigate = useNavigate()
  const search = new URLSearchParams(useLocation().search)
  const success = search.get('success') || ''
  const premium = search.get('premium') || ''
  const { user } = session

  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    return null
  }

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const isXLScreen = isLGScreen

  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(isXLScreen)
  const [ovdHideNav, setOvdHideNav] = React.useState(isXLScreen)

  const [redirecting, setRedirecting] = React.useState(false)
  const [isValidating, setValidating] = React.useState(false)

  const [validateSubscription] = useEvent([
    userSlice.eventBus.validateSubscription
  ])
  const [getUserData] = useEvent([userSlice.eventBus.getUserData])
  const isSubscriptionValidated = useSelector(
    userSlice.selectors.selectSubscriptionValidated
  )
  const subscriptionError = useSelector(
    userSlice.selectors.selectSubscriptionError
  )

  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }

  const buildUrlParams = (name, email) => {
    const [firstName, lastName] = name.split(' ')
    if (lastName) {
      return `first_name=${firstName}&last_name=${lastName}&account_code=${email}&email=${email}`
    }
    return `first_name=${firstName}&account_code=${email}&email=${email}`
  }

  const handleRedirect = React.useCallback(
    () => {
      const { attributes } = user
      const { name, email } = attributes
      const urlParams = buildUrlParams(name, email)
      window.location.href = `${process.env.REACT_APP_RECURLY_URL}?${urlParams}`
    }, [user])

  const clickUpgrade = () => {
    setRedirecting(true)
    const timer = setTimeout(() => {
      handleRedirect()
    }, 5000)
    return () => {
      clearTimeout(timer)
    }
  }

  //  const handleTryAgain = () => {
  //    dispatch(userSlice.actions.clearSubscriptionError())
  //    validateSubscription.trigger()
  //  }

  useEffect(() => {
    if (isLGScreen) setShowNavBar(true)
    if (isMDScreen) setShowNavBar(false)
  }, [isLGScreen, isMDScreen])

  useEffect(() => {
    if (!hj) return
    hj('stateChange', '/upgrade')
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: '/upgrade'
      }
    })
  })

  useEffect(() => {
    if (success === 'true') {
      setValidating(true)
      validateSubscription.trigger()
      if (hj) {
        hj('stateChange', '/upgrade?success=true')
        dataLayer.push({
          event: 'stateChange',
          attributes: {
            path: '/upgrade?success=true'
          }
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, validateSubscription])

  useEffect(() => {
    if (isSubscriptionValidated && !subscriptionError) {
      getUserData.trigger()
      setValidating(false)
      navigate('/team-create')
    }
  }, [isSubscriptionValidated, subscriptionError, navigate, getUserData])

  useEffect(() => {
    if (subscriptionError) {
      setTimeout(() => {
        dispatch(userSlice.actions.clearSubscriptionError())
      }, 5000)
    }
  }, [subscriptionError, dispatch])

  useEffect(() => {
    if (premium === 'pending') {
      setRedirecting(true)
      const timer = setTimeout(() => {
        handleRedirect()
      }, 5000)
      return () => {
        clearTimeout(timer)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premium])

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        hideHamburger
      />
      {isValidating && (
        <PaymentConfirmationComponent />
      )}
      {redirecting && (
        <TeamPaymentLoading />
      )}
      {!redirecting && !validateSubscription.isWorking && (
        <Grid container>
          <ScrollToTop />
          <Grid item lg={2}>
            <NavigationDrawer
              isLGScreen={isLGScreen}
              showNavBar={showNavBar}
              closeNavBar={closeNavBar}
              hideNav={hideNav}
              ovdHideNav={ovdHideNav}
              onMouseOver={handleNavDrawerHover}
            />
            {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && !isXLScreen && (
              <IconButton
                aria-label='toggle navigation menu'
                className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? classes.collapseBtn : classes.collapseBtnHide}
                onClick={toggleHideNav}
              >
                {
                  !hideNav
                    ? (
                      <KeyboardArrowLeft
                        className={classes.collapseIcon}
                      />
                      )
                    : (
                      <KeyboardArrowRight
                        className={classes.collapseIcon}
                      />
                      )
                }
              </IconButton>
            )}
          </Grid>
          <Grid container>
            <div
              className={isXLScreen || isLGScreen ? `${classes.mainMD} ${classes.mainXL}` : classes.main}
              onMouseOver={handleContentHover}
              id='main'
            >
              <Grid container spacing={6}>
                <TeamAlignedComponent handleUpgrade={clickUpgrade} />
                {isLGScreen && (
                  <Grid item lg={1} />
                )}
                <Grid item lg={5} md={12}>
                  <img src='/img/premium_badge.png' alt='Premium badge' width='170px' style={{ marginTop: 46 }} />
                  <div className={classes.imgBox}>
                    <img width='320px' src='/img/team-upgrade.png' alt='Team' />
                  </div>
                </Grid>
                <WhyUpgradeComponent handleUpgrade={clickUpgrade} />
                {isLGScreen && (
                  <Grid item lg={1} />
                )}
                <TeamUpgradeHowItWorks handleUpgrade={clickUpgrade} />
                {isLGScreen && (
                  <Grid lg={2} />
                )}
                <Grid item lg={8} md={12}>
                  <TeamFAQ />
                </Grid>
                {isLGScreen && (
                  <Grid lg={2} />
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
      {subscriptionError && (
        <PageAlert error text='We could not validate your subscription. Please try again and contact lpbenchmark@mindtools.com if the error persists' />
      )}
    </div>
  )
}

export default ContactPage
