import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginTop: '40px'
  },
  rootForPdf: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '1000px',
    width: '1000px'
  },
  labelBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    '& p': {
      // whiteSpace: 'nowrap',
    }
  },
  labelBoxForPdf: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    alignItems: 'center',
    '& p': {
      fontSize: '30px !important'
    }
  },
  label: {
    margin: '0 5px',
    display: 'block',
    width: 10,
    height: 10,
    borderRadius: '50%'
  },
  labelForPdf: {
    width: 15,
    height: 15,
    margin: '0 10px',
    display: 'block',
    borderRadius: '50%'
  },
  footer: {
    marginTop: '-80px',
    '@media (min-width: 900px) and (max-width: 1240px)': {
      marginTop: '-130px'
    }
  },
  footerForPdf: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    marginTop: '-220px',
    marginLeft: '85px',
    width: '595px'
  },
  svgContainer: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden'
  },
  svgContainerForPdf: {
    display: 'inline-block',
    position: 'relative',
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
    overflow: 'hidden',
    '& svg': {
      marginTop: '-35px !important'
    }
  }
}))
