import React from 'react'
import { MailtoLink } from '../index'
import { Typography, LinearProgress } from '@material-ui/core'
import { useStyles } from './style'

const SurveyCalculatingResults = () => {
  const classes = useStyles()

  return (
    <div className={classes.loading}>
      <img
        src='/img/calculating.png'
        alt='Calculating results'
        className={classes.loadImg}
      />
      <Typography variant='h1' className={classes.loadText}>
        Calculating your results... sit tight!
      </Typography>
      <Typography variant='h1' className={classes.loadText}>
        We'll be with you in just one moment.
      </Typography>
      <LinearProgress color='secondary' className={classes.progress} />
      <Typography variant='body1' className={classes.loadFooter}>
        © 2022 Emerald Works Limited. All rights reserved. Having trouble? Email
        us at{' '}
        <MailtoLink data-test='supportEmail' email='lpbenchmark@mindtools.com'>
          lpbenchmark@mindtools.com
        </MailtoLink>
      </Typography>
    </div>
  )
}

export default SurveyCalculatingResults
