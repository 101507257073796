import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '12px'
  },
  introText: {
    fontSize: 20,
    fontWeight: 400
  },
  img: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '32px'
  },
  tabText: {
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Matter'
  },
  greyHr: {
    border: 0,
    height: '1px',
    marginTop: '-1px',
    backgroundColor: 'rgba(0,0,0,0.1)'
  }
}))
