import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

import React, { useState } from 'react'
import { useStyles } from './style'

const Dropdown = ({ text, dropdown = 'Why is this important?' }) => {
  const classes = useStyles()
  const [visible, setVisible] = useState(false)
  return (
    <Container className={classes.root}>

      <div focusable onClick={() => setVisible(prev => !prev)} onKeyPress={() => setVisible(prev => !prev)} className={`${classes.dropdownContainer} ${classes.row} ${classes.pointer} `} tabindex={0}>

        <Typography className={classes.dropdown} aria-label='Why is this important?' focusable>{dropdown}</Typography>
        {!visible
          ? <IconButton aria-label='open dropdown' tabindex={-1} disableRipple className={classes.dropdownArrow}><KeyboardArrowDownIcon /></IconButton>
          : <IconButton aria-label='close dropdown' tabindex={-1} disableRipple className={classes.dropdownArrow}><KeyboardArrowUpIcon /></IconButton>}
      </div>

      <div className={classes.row}>
        {
          visible && (
            <Typography className={classes.dropdownText}>
              {
                text.split('\n').map((i, key) => (
                  <div key={key}>{i}</div>
                ))
              }
            </Typography>
          )
        }
      </div>

    </Container>
  )
}

export default Dropdown
