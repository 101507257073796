import React from 'react'
import {
  Dialog,
  Typography,
  Button
} from '@material-ui/core'
import { useStyles } from './style'

const IncompleteDialog = ({
  open,
  handleClose
}) => {
  const classes = useStyles()

  const dialogClose = () => {
    handleClose()
  }

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3'>Not enough filled in</Typography>
        <Typography className={classes.text}>You’ve not completed enough of the review to generate your personal results. Please go back and add a few more answers.</Typography>
        <div className={classes.buttons}>
          <Button variant='contained' color='secondary' onClick={dialogClose}>Return to review</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default IncompleteDialog
