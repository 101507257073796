import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import styles from './style'
import globalStyles from '../../style'
const KeyQuestions = ({ questions }) => {
  return (
    <View style={styles.blueBox}>
      <Text style={[globalStyles.subheading, { color: 'white', marginTop: 0, marginBottom: 0 }]}>Key questions to consider:</Text>
      <Text style={[globalStyles.body, { color: 'white', fontSize: 12, marginVertical: 5 }]}>1. {questions[0]}</Text>
      <Text style={[globalStyles.body, { color: 'white', fontSize: 12, marginVertical: 5 }]}>2. {questions[1]}</Text>
    </View>
  )
}
export default KeyQuestions
