import React, { useEffect } from 'react'
import { useStyles } from './style'
import {
  AppToolbar,
  NavigationDrawer,
  WelcomeDialog,
  IncompleteDialog,
  FinalDialog,
  SurveyBoxAlert,
  PageAlert,
  ProfileDialog,
  SurveyCalculatingResults,
  SurveySkeleton,
  SurveyStepHeader,
  SurveyNavButtons,
  SurveySectionCover,
  ReportPDF,
  GraphsForPDF
} from '../../components'
import SurveyComponent from './surveyJS'
import { useSelector, useDispatch } from 'react-redux'
import { surveySlice, userSlice, dashboardSlice } from '../../reducers'
import { Typography, Button, Grid, CircularProgress, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import survey from './sections'
import { surveySections } from './util'
import { useNavigate, useLocation } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { datadogRum } from '@datadog/browser-rum'
import { getStageByScore } from '../../helpers/utils'
import { basedURL } from '../../helpers/domain'
import { pdf } from '@react-pdf/renderer'
import { parseTrendsResponses } from '../dashboard/helpers'

const SurveyPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatcher = useDispatch()
  const search = new URLSearchParams(useLocation().search)
  const currentSurveyStep = useSelector(surveySlice.selectors.selectPage)
  const surveyInnerStep = useSelector(surveySlice.selectors.selectInnerPage)
  const surveyResponses = useSelector(surveySlice.selectors.selectResponses)
  const stepsProgress = useSelector(surveySlice.selectors.selectStepsProgress)
  const percentageCompleted = useSelector(surveySlice.selectors.selectPercentageCompleted)
  const userResults = useSelector(surveySlice.selectors.selectResults)
  const isCalculatingResults = useSelector(surveySlice.selectors.selectCalculatingResults)
  const responsesError = useSelector(surveySlice.selectors.selectResponsesError)
  const saveError = useSelector(surveySlice.selectors.selectSaveError)
  const userData = useSelector(userSlice.selectors.selectUserData)
  const unansweredProfileQuestions = useSelector(surveySlice.selectors.selectUnansweredProfileQuestions)
  const reportImagesLoaded = useSelector(dashboardSlice.selectors.selectReportImagesLoaded)
  const initialSection = surveyInnerStep.length ? surveyInnerStep : Object.keys(surveySections[currentSurveyStep].sections)[0]
  const [sectionStep, setSectionStep] = React.useState(surveySections[currentSurveyStep].sections[initialSection])
  const [saveSurveyResponses] = useEvent([surveySlice.eventBus.saveSurveyResponses])
  const [retrieveUserResponses] = useEvent([surveySlice.eventBus.retrieveUserResponses])
  const [updateUserFirstLogin] = useEvent([userSlice.eventBus.updateUserFirstLogin])
  const [getChartData] = useEvent([surveySlice.eventBus.getChartData])
  const [submitSurvey] = useEvent([surveySlice.eventBus.submitSurvey])
  const [updateUserTeam] = useEvent([userSlice.eventBus.updateUserTeam])
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [isProfileDialogOpen, setIsProfileDialogOpen] = React.useState(false)
  const [isIncompleteDialogOpen, setIsIncompleteDialogOpen] = React.useState(false)
  const [isFinalDialogOpen, setIsFinalDialogOpen] = React.useState(false)
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const isTeams = userData?.teams?.length || userData?.premium === 'team'
  const teamId = search.get('teamId') || ''

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  // temp
  const isXLScreen = isLGScreen

  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(false)
  const [ovdHideNav, setOvdHideNav] = React.useState(false)

  const openNavBar = () => {
    setShowNavBar(true)
    dispatcher(userSlice.actions.setActiveMenuSection('you'))
    dispatcher(userSlice.actions.setMenuLevel(1))
  }
  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }

  const isNewSeason = userData?.reviewNewSeason

  const getStepName = () => {
    switch (currentSurveyStep) {
      case 0:
        return 'profile'
      case 1:
        return 'vision'
      case 2:
        return 'strategy'
      case 3:
        return 'technology'
      case 4:
        return 'challenges'
      case 5:
        return 'resources'
      default:
        return 'final'
    }
  }

  const currentSectionStep = sectionStep || surveySections[currentSurveyStep].sections[`${initialSection}_1`]
  const isSurveyStep = Boolean(currentSectionStep.json)
  const currentJson = isSurveyStep && Object.keys(survey).indexOf(currentSectionStep.json) > -1 ? survey[currentSectionStep.json] : survey.personal
  const isSaving = saveSurveyResponses.isWorking
  const finalPage = currentJson.name === 'Finishing Page Marketing'
  const disableBackButton = (isSurveyStep && currentSurveyStep === 0 && currentSectionStep.json === 'personal')
  const saveExitBtnId = `btn-save-and-exit-${getStepName()}`
  const startBtnId = `btn-start-${getStepName()}`

  // pdf upload
  const [isUploading, setIsUploading] = React.useState(false)
  const [getSignedUrlForReport] = useEvent([surveySlice.eventBus.getSignedUrlForReport])
  const [getReportUrl] = useEvent([surveySlice.eventBus.getReportUrl])
  const isPDFDownloading = useSelector(surveySlice.selectors.selectPDFDownloading)
  // redux debugging

  const signedUrlForReport = useSelector(surveySlice.selectors.selectSignedUrlForReport)
  const readingLinks = useSelector(surveySlice.selectors.selectReadings)
  const chartData = useSelector(surveySlice.selectors.selectChartData)
  const userScore = chartData ? Number.parseInt(chartData.results.score) : 0
  const userStage = chartData ? getStageByScore(userScore) : 1
  const userResultsSeason = chartData ? chartData.results.season : 2022
  const scoresChartData = chartData ? chartData.scoresChartData : []
  const componentsChartData = chartData ? chartData.componentsChartData : {}
  const responses = chartData ? chartData.responses : []
  const { skillsResponses, techResponses, barrierResponses } = parseTrendsResponses(responses)

  const handlePageView = () => {
    if (!hj || !datadogRum) return
    datadogRum.startView(`/review/${currentSurveyStep}${surveyInnerStep ? `/${surveyInnerStep}` : '/cover'}`)
    hj('stateChange', `review/${currentSurveyStep}${surveyInnerStep ? `/${surveyInnerStep}` : '/cover'}`)
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: `/review/${currentSurveyStep}${surveyInnerStep ? `/${surveyInnerStep}` : '/cover'}`
      }
    })
  }

  useEffect(() => {
    if (isLGScreen) openNavBar()
    if (isMDScreen) setShowNavBar(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLGScreen, isMDScreen])

  useEffect(() => {
    if (!signedUrlForReport) {
      getSignedUrlForReport.trigger()
    }
  }, [signedUrlForReport, getSignedUrlForReport])

  useEventsOnViewLoad(() => {
    retrieveUserResponses.trigger({ step: currentSurveyStep, stepName: sectionStep ? sectionStep.json : undefined })

    if (teamId.length) {
      updateUserTeam.trigger({ teamId })
      search.delete('teamId')
      navigate({ search: search.toString() })
    }
  }, [retrieveUserResponses])

  useEventsOnViewLoad(() => {
    submitSurvey.registerAdhocOnSuccessListener(() => {
      getChartData.trigger()
      getReportUrl.trigger()
      getSignedUrlForReport.trigger()
    })
  }, [submitSurvey])

  useEffect(() => {
    if (userData.isFirstLogin) {
      setIsDialogOpen(true)
      updateUserFirstLogin.trigger()
    }
    window.scrollTo(0, 0)
    if (userResults !== null && userResults !== undefined && !isCalculatingResults && !isPDFDownloading && !isUploading) {
      navigate('/dashboard')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResults, isCalculatingResults, isPDFDownloading, updateUserFirstLogin])

  useEffect(() => {
    handlePageView()
    window.scrollTo(0, 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyInnerStep, currentSectionStep])

  useEffect(() => {
    if (!retrieveUserResponses.isWorking && sectionStep && sectionStep.json !== undefined) {
      retrieveUserResponses.trigger({ step: currentSurveyStep, stepName: sectionStep.json })
    }
  }, [sectionStep, currentSurveyStep, retrieveUserResponses])

  const handleDialogClose = () => {
    setIsDialogOpen(false)
    setIsIncompleteDialogOpen(false)
    setIsFinalDialogOpen(false)
  }

  const handleDialogFinish = () => {
    dispatcher(surveySlice.actions.setPDFDownloading(true))
    setIsUploading(true)
    // handleSurveySubmit()
    handleDialogClose()
    handleSurveySubmit()
    // handlePDFUpload()
  }

  if (unansweredProfileQuestions && unansweredProfileQuestions.length && currentSurveyStep > 0 && isProfileDialogOpen === false) {
    setIsProfileDialogOpen(true)
  }

  const handleCloseProfileDialog = () => {
    setSurveyStep(0)
    setIsProfileDialogOpen(false)
  }

  const startSection = () => {
    dataLayer.push({
      event: 'Click',
      attributes: {
        button: startBtnId
      }
    })
    getSectionNextStep()
  }

  const getSectionNextStep = () => {
    // check if we need to give the incomplete profile dialog
    if (!retrieveUserResponses.isWorking && unansweredProfileQuestions.length && currentSurveyStep === 0 && surveyInnerStep === 'organisation' && isProfileDialogOpen === false) {
      setIsProfileDialogOpen(true)
      return
    }
    const currentStepSections = surveySections[currentSurveyStep].sections
    const sectionNames = Object.keys(currentStepSections)
    const currentSectionIndex = sectionNames.indexOf(currentSectionStep.json || 'cover')
    const enableCondition = surveyResponses && surveyResponses.Role ? ['Business leader / senior manager (not L&D/HR)', 'Senior manager - HR', 'Senior manager - L&D overall', 'L&D strategy and management', 'Organisation Development'].indexOf(surveyResponses.Role) > -1 : false

    if (currentSurveyStep === 5 && surveyInnerStep === 'goals_1' && !enableCondition) {
      setSurveyStep(currentSurveyStep + 1)
    } else {
      if (currentSectionIndex + 1 < sectionNames.length) {
        if (currentSectionIndex === -1) {
          setSectionStep(surveySections[6].sections.final_2)
          setSurveyInnerStep('final_1')
          return
        }
        const newSectionName = sectionNames[currentSectionIndex + 1]
        const newSection = currentStepSections[newSectionName]
        setSectionStep(newSection)
        setSurveyInnerStep(newSectionName)
      } else {
        if (currentSurveyStep <= 4) {
          setSurveyStep(currentSurveyStep + 1)
        } else {
          setSurveyStep(currentSurveyStep + 1)
          setSectionStep(surveySections[6].sections.final_1)
        }
      }
    }
  }

  const handleBackClick = () => {
    const currentStepSections = surveySections[currentSurveyStep].sections
    const sectionNames = Object.keys(currentStepSections)
    const currentSectionIndex = sectionNames.indexOf(currentSectionStep.json || 'cover')
    const enableCondition = surveyResponses && surveyResponses.Role ? ['Business leader / senior manager (not L&D/HR)', 'Senior manager - HR', 'Senior manager - L&D overall', 'L&D strategy and management', 'Organisation Development'].indexOf(surveyResponses.Role) > -1 : false
    if (currentSurveyStep === 1 && (surveyInnerStep === 'goals_1' || surveyInnerStep === 'goals') && !enableCondition) {
      setSurveyInnerStep('resources_1')
    } else {
      if (currentSectionIndex - 1 >= 0) {
        const newSectionName = sectionNames[currentSectionIndex - 1]
        const newSection = currentStepSections[newSectionName]
        if (newSectionName === 'cover') {
          setSurveyStep(currentSurveyStep)
        } else {
          setSectionStep(newSection)
          setSurveyInnerStep(newSectionName)
        }
      } else {
        if (currentSurveyStep === 6) {
          setSurveyStep(currentSurveyStep - 1)
        } else {
          const newStepSections = surveySections[currentSurveyStep - 1].sections
          const newSectionNames = Object.keys(newStepSections)
          const newSectionName = newSectionNames[newSectionNames.length - 1]
          const newSection = newStepSections[newSectionName]
          setSectionStep(newSection)
          setSurveyInnerStep(newSectionName)
          dispatcher(surveySlice.actions.setPage(currentSurveyStep - 1))
        }
      }
    }
  }

  const setSurveyStep = step => {
    setSurveyInnerStep('')
    const stepFirstSectionName = Object.keys(surveySections[step].sections)[0]
    const initialSection = surveySections[step].sections[stepFirstSectionName]
    setSectionStep(initialSection)
    dispatcher(surveySlice.actions.setPage(step))
  }

  const setSurveyInnerStep = name => {
    if (name.split('_').length === 1 && currentSurveyStep !== 0 && currentSurveyStep !== 4) {
      const innerStepFirstSection = surveySections[currentSurveyStep].sections[`${name}_1`]
      setSectionStep(innerStepFirstSection)
      dispatcher(surveySlice.actions.setInnerPage(name))
    } else {
      setSectionStep(surveySections[currentSurveyStep].sections[name])
      dispatcher(surveySlice.actions.setInnerPage(name))
    }
  }

  const handleSaveAndExit = () => {
    dataLayer.push({
      event: 'Click',
      attributes: {
        button: saveExitBtnId
      }
    })

    navigate('/')
  }

  const handleSurveyChange = async ({ data }) => {
    const teamId = isTeams ? userData?.teams[0] : undefined
    saveSurveyResponses.trigger({ responses: data, teamId })
  }

  const handleSurveySubmit = async () => {
    dataLayer.push({
      event: 'Click',
      attributes: {
        button: 'btn-submit-review'
      }
    })
    submitSurvey.trigger({ baseUrl: window.location.protocol + '//' + basedURL })
  }

  const handlePDFUpload = async () => {
    const doc = (
      <ReportPDF
        scoresChartData={chartData.scoresChartData}
        componentsChartData={chartData.componentsChartData}
        profile={chartData.profile}
        behaviours={chartData.behaviours}
        stage={getStageByScore(Number.parseInt(chartData.results.score))}
        readingLinks={readingLinks}
      />
    )
    const asPdf = pdf([])
    asPdf.updateContainer(doc)
    const blob = await asPdf.toBlob()
    await fetch(signedUrlForReport, { body: blob, method: 'PUT' })
    setIsUploading(false)
    dispatcher(surveySlice.actions.setPDFDownloading(false))
  }
  useEffect(() => {
    if (!readingLinks) {
      getReportUrl.trigger()
    }

    if (!chartData || !isPDFDownloading || !isUploading || !reportImagesLoaded) {
      return
    }
    handlePDFUpload()
    return () => { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, readingLinks, isUploading, isPDFDownloading, reportImagesLoaded])

  const handleSubmit = () => {
    if (percentageCompleted < 75) {
      setIsIncompleteDialogOpen(true)
    } else if (percentageCompleted < 100) {
      setIsFinalDialogOpen(true)
      // dispatcher(surveySlice.actions.setPDFDownloading(true))
      // setIsUploading(true)
      // handleSurveySubmit()
    } else {
      dispatcher(surveySlice.actions.setPDFDownloading(true))
      setIsUploading(true)
      handleSurveySubmit()
    }
  }

  return (isCalculatingResults || isPDFDownloading || isUploading)
    ? (
      <>
        <SurveyCalculatingResults />
        {isPDFDownloading && chartData && (
          <GraphsForPDF
            componentsChartData={componentsChartData}
            scoresChartData={scoresChartData}
            stage={userStage}
            profile={chartData.profile}
            tech={techResponses}
            challenges={barrierResponses}
            currentSeason={userResultsSeason}
            skills={skillsResponses}
          />
        )}
      </>
      )
    : (
      <div className={classes.root}>
        <AppToolbar
          isMobileScreen={isMDScreen || isXSScreen}
          navBarOpen={showNavBar}
          openNavBar={openNavBar}
          closeNavBar={closeNavBar}
        />
        <Grid container>
          <Grid container item xs={2}>

            <NavigationDrawer
              activeSurveyStep={currentSurveyStep}
              activeSurveyInnerStep={surveyInnerStep}
              setActiveSurveyStep={setSurveyStep}
              setActiveSurveyInnerStep={setSurveyInnerStep}
              stepsProgress={stepsProgress}
              percentageCompleted={percentageCompleted}
              isLGScreen={isLGScreen}
              showNavBar={showNavBar}
              closeNavBar={closeNavBar}
              hideNav={hideNav}
              ovdHideNav={ovdHideNav}
              onMouseOver={handleNavDrawerHover}
            />
            {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && (
              <IconButton
                aria-label='toggle navigation menu'
                className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.collapseBtn} ${isXLScreen && classes.collapseBtnXL}` : `${classes.collapseBtnHide} ${isXLScreen && classes.collapseBtnHideXL}`}
                onClick={toggleHideNav}
              >
                {
                  !hideNav
                    ? (
                      <KeyboardArrowLeft
                        className={classes.collapseIcon}
                      />
                      )
                    : (
                      <KeyboardArrowRight
                        className={classes.collapseIcon}
                      />
                      )
                }
              </IconButton>
            )}
          </Grid>
          <Grid container>

            {isSurveyStep
              ? (
                <div
                  className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.main} ${isXLScreen && classes.mainXL}` : `${classes.mainMD} ${isXLScreen && classes.mainXLCollapsed}`}
                  onMouseOver={handleContentHover}
                  id='main'
                >
                  <Grid container spacing={3}>
                    <Grid item xs={10}>
                      {/*
                  <span className={classes.sectionBreadcrumbBox}>
                    <Typography variant='subtitle2' className={classes.sectionBreadcrumb}>{currentPage}</Typography>
                    <Typography variant='body2' className={classes.sectionBreadcrumb}>{currentSectionStep.name ? currentSectionStep.name : ''}</Typography>
                  </span>
                  */}
                    </Grid>
                    <Grid item xs={2} className={classes.savedButtonContainer}>
                      <Button variant='outlined' disableElevation disableFocusRipple disableRipple disableTouchRipple className={classes.savedButton} startIcon={isSaving ? <CircularProgress size={16} classes={{ root: classes.saving }} /> : <CheckIcon />}>Saved</Button>
                    </Grid>
                  </Grid>
                  <SurveyStepHeader header={currentSectionStep.header} />
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      {isNewSeason && currentSurveyStep === 0 && (
                        <SurveyBoxAlert text='Check the details you previously entered are still correct and up to date before proceeding to the next section.' />
                      )}
                      {surveyInnerStep === 'resources_2' && (
                        <Typography>The following questions look at the level of learning experience in your organisation.</Typography>
                      )}
                      {
                        retrieveUserResponses.isWorking || responsesError
                          ? (
                            <SurveySkeleton rows={4} />
                            )
                          : (
                            <SurveyComponent
                              json={currentJson}
                              onValueChanged={handleSurveyChange}
                              data={surveyResponses}
                              saveSurveyResponses={saveSurveyResponses}
                              unanswered={unansweredProfileQuestions}
                            />
                            )
                      }

                      {finalPage && (
                        <Typography style={{ marginTop: '48px' }} className={classes.introText}>Phew-that’s it! Let’s check out your personalised interactive results dashboard.</Typography>
                      )}
                      <SurveyNavButtons
                        finalPage={finalPage}
                        handleBackClick={handleBackClick}
                        disableBackButton={disableBackButton}
                        getSectionNextStep={getSectionNextStep}
                        handleSubmit={handleSubmit}
                        saveExitBtnId={saveExitBtnId}
                        handleSaveAndExit={handleSaveAndExit}
                      />
                    </Grid>
                  </Grid>
                </div>
                )
              : (
                <SurveySectionCover
                  section={currentSectionStep}
                  saveExitBtnId={saveExitBtnId}
                  handleSaveAndExit={handleSaveAndExit}
                  startBtnId={startBtnId}
                  startSection={startSection}
                  isLGScreen={isLGScreen}
                  hideNav={hideNav}
                  ovdHideNav={ovdHideNav}
                  handleContentHover={handleContentHover}
                  isXLScreen={isXLScreen}
                />
                )}

            <WelcomeDialog open={isDialogOpen} handleClose={handleDialogClose} />
            <IncompleteDialog open={isIncompleteDialogOpen} handleClose={handleDialogClose} />
            <FinalDialog open={isFinalDialogOpen} handleClose={handleDialogClose} handleFinish={handleDialogFinish} />
            <ProfileDialog open={isProfileDialogOpen} handleFinish={handleCloseProfileDialog} unanswered={unansweredProfileQuestions || []} />
            {responsesError && (
              <PageAlert title='loading responses' text='Something went wrong! Try refreshing your screen. If this error persists, please contact us' error />
            )}
            {saveError && (
              <PageAlert title='saving responses' text='Something went wrong! Try refreshing your screen. If this error persists, please contact us' error />
            )}
          </Grid>
        </Grid>

      </div>
      )
}

export default SurveyPage
