import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '25%'
  },
  graphContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '65%'
  },
  list: {
    display: 'flex',
    flexDirection: 'column'
  }
})
export default styles
