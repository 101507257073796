import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFF',
    height: '100%',
    '& tr:nth-child(even)': {
      background: '#fff'
    },
    '& tr:nth-child(odd):not(.sv-multipletext__row)': {
      background: '#EEEFF1'
    },
    '& thead tr td, & thead tr th': {
      background: '#fff'
    },
    '& tr:first-child:nth-last-child(1)': {
      background: '#fff'
    },
    '& .sv-matrixdropdown td': {
      background: '#fff'
    },
    '& .sv-table td:first-child, .sv-table th:first-child': {
      paddingLeft: '1.875em',
      fontSize: '16px',
      lineHeight: '22px',
      fontFamily: 'Matter',
      letterSpacing: '0.5px',
      verticalAlign: 'middle'
    },
    '& .sv-table tbody tr:last-child .sv-table__cell': {
      paddingBottom: '1em'
    },
    '& .sv-table tr:first-child .sv-table__cell': {
      paddingTop: '1em'
    },
    '& .sv-matrix .sv-table__cell--header': {
      fontSize: '12px',
      lineHeight: '16px',
      fontFamily: 'Matter',
      fontWeight: '400',
      letterSpacing: '0.4px',
      '& span': {
        display: 'inline-block',
        width: '120px !important'
      }
    },
    '& .sv-table__cell:not(:first-child)': {
      paddingLeft: 0
    },
    '& .sv-table__cell:not(:last-child)': {
      paddingRight: 0
    },
    '& .sv-table__cell': {
      verticalAlign: 'middle',
      minWidth: '10em'
    }

  },
  main: {
    width: '100%',
    padding: '48px',
    marginTop: '40px',
    height: '100%',
    transition: 'margin-left 0.25s ease-in-out',
    marginLeft: '296px'
  },
  mainMD: {
    padding: '24px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px',
    height: '100%',
    width: '100%'
  },

  mainXL: {
    marginLeft: '368px'
  },

  mainXLCollapsed: {
    marginLeft: '72px'
  },

  savedButton: {
    margin: '0 16px',
    color: '#006FB7',
    cursor: 'default',
    minWidth: '90px',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  savedButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveExitButton: {
    margin: '0 16px 0 0',
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  sectionBreadcrumbBox: {
    display: 'flex',
    height: '100%',
    alignItems: 'center'
  },
  sectionBreadcrumb: {
    color: 'rgba(37, 55, 70, 0.38)',
    margin: '0px 2px',
    lineHeight: '1.57 !important'
  },
  sectionTitle: {
    marginBottom: '1.5rem'
  },
  introText: {
    fontSize: 20,
    fontWeight: 400,
    marginBottom: '16px'
  },
  img: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    marginTop: '10%'
  },
  tipsBox: {
    color: '#FFF',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '4px',
    padding: '24px',
    margin: '64px 0'
  },
  quoteTextBox: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  quoteText: {
    width: '25rem'
  },
  quoteAuthor: {
    paddingTop: '0.75rem'
  },
  quoteRole: {
    width: '22rem'
  },
  buttonsBox: {
    marginBottom: '64px'
  },
  navBackButton: {
    marginRight: '16px',
    color: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s',
      color: '#FFFFFF'
    }
  },
  navExitButton: {
    display: 'flex',
    float: 'right',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: '#E9ECED',
      transition: 'background-color 0.2s'
    }
  },
  saving: {
    color: '#006FB7'
  },
  primaryButton: {
    backgroundColor: '#006FB7',
    '&:hover': {
      backgroundColor: '#024E95',
      transition: 'background-color 0.2s'
    }
  },
  divButtons: {
    paddingTop: '3rem'
  },
  bulletList: {
    paddingInlineStart: '19px'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },
  collapseBtnHideXL: {
    left: '73px',
  },

  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '282px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },

  collapseBtnXL: {
    left: '354px',
  },

  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  }
}))
