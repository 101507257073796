import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  imgHover: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '90%',
    height: 'auto',
    cursor: 'pointer'
  },
  homePaper: {
    padding: '16px 24px',
    minHeight: '380px'
  },
  homePaperContent: {
  },
  footer: {
    paddingTop: '1.25rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    textTransform: 'uppercase'
  },
  footerButton: {
    cursor: 'pointer'
  },
  homePaperImage: {
    display: 'block',
    margin: '0 auto',
    maxWidth: '80%',
    height: 'auto',
    cursor: 'pointer'
  },
  cardTitle: {
    marginTop: 0
  },
  fixHeight: {
    minHeight: '230px'
  }
}))
