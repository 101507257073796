import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'
Font.register({ family: 'Matter', src: Matter })
const styles = StyleSheet.create({
  smallText: {
    fontSize: 8,
    fontFamily: 'Matter'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    transform: 'scale(0.9)'
  }

})
export default styles
