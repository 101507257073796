import React from 'react'
import { DashboardReading } from '../../components'
import { Grid, Typography, Tabs, Tab } from '@material-ui/core'
import { useStyles } from './style'
import { Skeleton } from '@material-ui/lab'
const tabs = [
  {
    url: 'Technology',
    label: 'Technology',
    type: 'tech',
    caption: 'Top five trending technologies',
    trends: {
      2021: [
        {
          text: 'Virtual meetings (e.g. Skype, Google Hangouts, Zoom)',
          used: true,
          percentage: 82,
          change: 3
        },
        {
          text: 'Job aids (e.g. pdf checklists, infographics)',
          used: false,
          percentage: 75,
          change: -4
        },
        {
          text: 'Webinars (e.g. GoToWebinar, Webex, Livestream, Adobe Connect)',
          used: false,
          percentage: 74,
          change: 6
        },
        {
          text: 'Communication tools (chat, IM, SMS, newsletter, forum)',
          used: true,
          percentage: 74,
          change: -3
        },
        {
          text: 'Mobile devices (e.g. smartphones, tablets)',
          used: false,
          percentage: 68,
          change: -1
        }
      ],
      2022: [
        {
          text: 'Communication tools (e.g., instant messaging, forums)',
          used: true,
          percentage: 80,
          change: 5
        },
        {
          text: 'Virtual classrooms (live)',
          used: false,
          percentage: 78,
          change: 24
        },
        {
          text: 'Webinar (e.g., Webex, Adobe Connect)',
          used: false,
          percentage: 74,
          change: 0
        },
        {
          text: 'Job aids (e.g., PDF checklists, infographics)',
          used: true,
          percentage: 72,
          change: -3
        },
        {
          text: 'Mobile devices (smartphones, tablets)',
          used: false,
          percentage: 69,
          change: 1
        }
      ]
    }
  },
  {
    url: 'Challenges',
    label: 'Challenges',
    type: 'challenges',
    caption: 'Top five top challenges',
    trends: {
      2021: [
        {
          text: 'Reluctance by managers to make time for learning',
          used: true,
          percentage: 44,
          change: -1
        },
        {
          text: 'L&D are overwhelmed and under-equipped',
          used: false,
          percentage: 40,
          change: 1
        },
        {
          text: 'Leaders have traditional expectations of L&D that are difficult to challenge',
          used: false,
          percentage: 35,
          change: -1
        },
        {
          text: 'Lack of investment to future-proof L&D approaches',
          used: true,
          percentage: 33,
          change: -2
        },
        {
          text: 'Cost of set-up, development and maintenance of tech solutions',
          used: false,
          percentage: 31,
          change: -1
        }
      ],
      2022: [
        {
          text: 'Reluctance by managers to make time for learning',
          used: true,
          percentage: 42,
          change: -2
        },
        {
          text: 'Overwhelmed and underequipped',
          used: false,
          percentage: 38,
          change: -2
        },
        {
          text: 'Leaders have traditional expectations about L&D which are difficult to change',
          used: false,
          percentage: 35,
          change: -1
        },
        {
          text: 'Lack of investment in future-proofing our L&D strategy',
          used: true,
          percentage: 31,
          change: -2
        },
        {
          text: 'Lack of analytical skills to use learning data effectively',
          used: false,
          percentage: 30,
          change: 2
        }
      ]
    }
  },
  {
    url: 'Skills',
    label: 'In-house skillsets',
    type: 'skills',
    caption: 'Top five in-house skillsets',
    trends: {
      2022: [
        {
          text: 'In-person classroom delivery',
          used: true,
          percentage: 77,
          change: 0
        },
        {
          text: 'Learning management / administration',
          used: false,
          percentage: 61,
          change: 0
        },
        {
          text: 'Virtual classroom / webinar delivery',
          used: false,
          percentage: 61,
          change: 22
        },
        {
          text: 'Evaluating impact',
          used: true,
          percentage: 57,
          change: 3
        },
        {
          text: 'Crafting learning journeys/blended solutions',
          used: false,
          percentage: 47,
          change: 7
        }
      ],
      2021: [
        {
          text: 'Classroom / F2F learning / training delivery',
          used: true,
          percentage: 77,
          change: -5
        },
        {
          text: 'Learning management / administration',
          used: false,
          percentage: 61,
          change: -5
        },
        {
          text: 'Facilitation and assessment',
          used: false,
          percentage: 54,
          change: -6
        },
        {
          text: 'Programme evaluation',
          used: true,
          percentage: 45,
          change: -10
        },
        {
          text: 'Coaching and mentoring',
          used: false,
          percentage: 44,
          change: -7
        }
      ]
    }
  }
]

const readings = {
  Technology: [
    {
      title: 'L&D: Where are we now?',
      description:
        'Understand what success in business look like in a constant world of change and disruption',
      color: '#FF8200',
      img: '/img/readings/l_d-where-are-we-now.png',
      link: 10
    },
    {
      title:
        'Back to the future: why tomorrow’s workforce needs a learning culture',
      description:
        'It’s great to see more organisations investing in L&D—but why are so many of them still witnessing impact drop? Discover ways to build a high-impact learning culture—and find out how to ensure its success.',
      color: '#B1C9E8',
      img: '/img/readings/back-to-the-future.png',
      link: 6
    },
    {
      title: 'Who moved my skills?',
      description:
        'Every employee faces competing priorities, but you can ameliorate this challenge by nurturing a culture of both upskilling and reskilling.',
      color: '#B7312C',
      img: '/img/readings/who-moved-my-skills.png',
      link: 16
    }
  ],
  Challenges: [
    {
      title: 'Bridging the divide',
      description:
        'L&D means taking positive action to grow and support your learners. How can you adapt your practices for the modern workforce?',
      color: '#B1C9E8',
      img: '/img/readings/bridging-the-divide.png',
      link: 1
    },
    {
      title: 'Beyond blending',
      description:
        'Discover how top-performing learning organisations establish systems that balance formal learning with accessibility—and imagine how to emulate them.',
      color: '#B1C9E8',
      img: '/img/readings/beyond-blending.png',
      link: 0
    },
    {
      title: 'How to build and change habits in the workplace',
      description:
        'Adapt the working environment to increase efficiency, shape behavioural change - and effect genuine, long-lasting change.',
      color: '#B7312C',
      img: '/img/readings/how-to-build-and-change-habits-in-the-workplace.png',
      link: 8
    }
  ],
  Skills: [
    {
      title: 'Professionalising learning and development',
      description:
        'The business landscapes is more volatile than ever. Is your organisation primed to adapt to the relentless pace of change?',
      color: '#FF8200',
      img: '/img/readings/professionialising learning-and-development.png',
      link: 12
    },
    {
      title: 'Driving performance and productivity',
      description:
        'By leveraging data insights, you can enhance your organisation\'s learning experience, and implement a strategy that\'s both responsive and impactful.',
      color: '#FF8200',
      img: '/img/readings/driving-performance-and-productivity.png',
      link: 4
    },
    {
      title: 'L&D’s relationship with data',
      description:
        'The ability to explore and analyse data should be one of your organisation’s highest-order priorities.',
      color: '#006FB7',
      img: '/img/readings/l_ds-relationship-with-data.png',
      link: 9
    }
  ]
}

const DashboardTrendsSkeleton = () => {
  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={8}>
        <Skeleton>
          <Typography variant='h1'>Overall participant trends</Typography>

        </Skeleton>
        <Skeleton>

          <Typography className={classes.introText}>
            Based on comprehensive data derived from every response to the
            Learning Performance Benchmark.
          </Typography>
        </Skeleton>
      </Grid>
      <Grid item xs={4}>
        <Skeleton>

          <div className={classes.img}>
            <img src='/img/dashboard-trends.png' alt='' />
          </div>
        </Skeleton>
      </Grid>
      <Grid item xs={12}>
        <Skeleton>

          <Tabs
            classes={{ flexContainer: classes.tabs }}
            value='Technology'
            aria-label='Overall participant trends'
          >
            {tabs.map(tab => (
              <Tab
                key={tab.url}
                value={tab.url}
                label={tab.label}
                id={`wrapped-tab-${tab.url}`}
                wrapped
                data-test={`tab-${tab.url}`}
                className={classes.tabText}
              />
            ))}
          </Tabs>
        </Skeleton>
        <hr className={classes.greyHr} />

        <Skeleton variant='rect' width='100%' height={500} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton>

          <Typography variant='h2'>Further reading</Typography>
        </Skeleton>
        <Grid container spacing={3}>
          {readings.Technology.map((reading, idx) => (
            <Grid item xs={12} sm={12} md={4} lg={4} key={idx}>
              <Skeleton>

                <DashboardReading
                  title={reading.title}
                  text={reading.description}
                  idx={idx}
                  color={reading.color}
                  image={reading.img}
                  link={reading.link}
                />
              </Skeleton>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default DashboardTrendsSkeleton
