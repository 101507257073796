import { Paper, Typography, CircularProgress } from '@material-ui/core'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutline'
import { useStyles } from './style'

import React from 'react'

const getCardHeaders = type => {
  return type === 'name'
    ? 'Name your team'
    : type === 'information'
      ? 'Team information'
      : 'Add your team members'
}

const getCardDescriptions = type => {
  return type === 'name'
    ? ['Please note, this can`t be changed']
    : type === 'information'
      ? [
          'These details enable us to build your personalised dashboard  and mean we can  accurately benchmark you not only against your team, but also to other organistaion similar to yours.',
          'If you have already completed a review, check the details you previously entered are still correct and up to date before proceeding.'
        ]
      : [
          'Add anyone you`d like to complete the Learning Performance Benchmark. You can add or remove members at any time.',
          '(Maximun of 20 members per team, including you)'
        ]
}

const TeamDetailsCard = ({ type, completed, expanded, loading, children }) => {
  const classes = useStyles()
  const cardHeader = getCardHeaders(type)
  const cardDescriptions = getCardDescriptions(type)

  return (
    <Paper className={classes.root}>
      <Typography
        variant='h2'
        className={!expanded && !completed
          ? classes.cardCollapsedHeader
          : !completed
              ? classes.cardHeader
              : classes.cardCompletedHeader}
      >
        {cardHeader}
      </Typography>
      {expanded && cardDescriptions.map((text, idx) => (
        <Typography key={idx} className={classes.cardDescription}>
          {text}
        </Typography>
      ))}
      {expanded && children}
      {completed && (
        <CheckCircleOutlinedIcon className={classes.icon} />
      )}
      {expanded && loading && (
        <CircularProgress size={24} className={classes.icon} />
      )}
    </Paper>
  )
}

export default TeamDetailsCard
