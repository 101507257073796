import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  buttons: {
    marginTop: '40px',
    display: 'flex',
    flexDirection: 'row'
  },
  leftBox: {
    paddingLeft: '5%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  rightBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
