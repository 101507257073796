import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  stagesBox: {
    display: 'flex',
    height: '100%',
    alignItems: 'flex-end'
  },
  step2: {
    marginBottom: '26px'
  },
  step3: {
    marginBottom: '52px'
  },
  step4: {
    marginBottom: '78px'
  },
  bigStep2: {
    marginBottom: '62px',
    '@media (min-width: 1660px)': {
      marginBottom: '62px'
    },
    '@media (max-width: 1576px)': {
      marginBottom: '74px'
    }
  },
  bigStep3: {
    marginBottom: '153px',
    '@media (min-width: 1660px)': {
      marginBottom: '124px'
    },
    '@media (min-width: 1576px) and (max-width: 1660px)': {
      marginBottom: '142px'
    }

  },
  bigStep4: {

    '@media (max-width: 1660px) and (min-width: 1576px)': {
      marginBottom: '217px'
    },
    '@media (max-width: 1576px) and (min-width: 1231px)': {
      marginBottom: '227px'
    },
    '@media (max-width: 1231px)': {
      marginBottom: '252px'
    },
    '@media (min-width: 1660px)': {
      marginBottom: '199px'
    },

    marginBottom: '220px'

  },
  stage: {
    width: '25%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  currentStage: {
    display: 'flex',
    flexDirection: 'column',
    width: '25%'
  },
  stageImage: {
    margin: '0 auto'
  },
  stageText: {
    fontSize: '12px',
    borderLeft: '8px solid rgba(37, 55, 70, 0.38)',
    borderTop: '8px solid rgba(37, 55, 70, 0.38)',
    color: 'rgba(37, 55, 70, 0.38)',
    fontWeight: 700,
    margin: '4px 0 0 4px',
    padding: '8px 0 0 8px'
  },
  currentStageText: {
    fontSize: '12px',
    borderLeft: '8px solid rgba(37, 55, 70, 1)',
    borderTop: '8px solid rgba(37, 55, 70, 1)',
    color: 'rgba(37, 55, 70, 1)',
    fontWeight: 700,
    margin: '4px 0 0 4px',
    padding: '8px 0 0 8px'
  },
  bigStageText2: {
    marginLeft: '15%',
    position: 'absolute',
    top: 222,
    left: 12
  },
  bigStageText3: {
    marginLeft: '30%',
    position: 'absolute',
    top: 165,
    left: 12
  },
  bigStageText4: {
    marginLeft: '45%',
    position: 'absolute',
    top: 108,
    left: 12
  },
  verticalText: {
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70px',
    borderLeft: '8px solid rgba(37, 55, 70, 0.38)',
    borderTop: '8px solid rgba(37, 55, 70, 0.38)',
    padding: '8px 0 0 8px',
    marginLeft: '4px',
    marginRight: '4px',
    '& p, & span': {
      color: 'rgba(37, 55, 70, 0.38) !important'
    }
  },
  verticalBigText: {
    width: '99%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '70px',
    borderLeft: '8px solid #343337',
    borderTop: '8px solid #343337',
    padding: '8px 0 0 8px',
    marginLeft: '4px',
    marginRight: '4px'
  },
  bigText: {
    fontSize: '16px',
    fontWeight: 700
  },
  bigRoot1: {
    position: 'relative',
    marginTop: '32px',
    height: '48%'
  },
  bigRoot: {
    position: 'relative',
    marginTop: '32px',
    height: '60%'
  },
  stageSteps: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  firstStageStep: {
    width: '50%',
    padding: '22px',
    borderRadius: '4px 0 0 4px',
    textAlign: 'center',
    marginRight: '1px',
    backgroundColor: '#4ABFF8'
  },
  secondStageStep: {
    width: '13%',
    padding: '22px',
    textAlign: 'center',
    marginRight: '1px',
    backgroundColor: '#0095E5'
  },
  thirdStageStep: {
    width: '10%',
    padding: '22px',
    textAlign: 'center',
    marginRight: '1px',
    backgroundColor: '#0071BD'
  },
  fourthStageStep: {
    width: '27%',
    padding: '22px',
    borderRadius: '0 4px 4px 0',
    textAlign: 'center',
    backgroundColor: '#03519B'
  },
  currentImpactText: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.4px'
  }
}))
