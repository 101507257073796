import { StyleSheet, Font } from '@react-pdf/renderer'
import Matter from '../../../../fonts/Matter-Regular.ttf'
import MatterBold from '../../../../fonts/Matter-Bold.ttf'
Font.register({ family: 'Matter', src: Matter })
Font.register({ family: 'MatterBold', src: MatterBold })
const styles = StyleSheet.create({
  full: {
    width: '100%',
    height: '100%'
  },
  pageBackground: {
    position: 'absolute',
    minWidth: '100%',
    minHeight: '100%',
    display: 'block',
    height: '100%',
    width: '100%'
  },
  logo: {
    width: '200px',
    position: 'absolute',
    right: '5%',
    top: '5%'
  },
  textContainer: {
    position: 'absolute',
    left: 30,
    bottom: '35%'
  },
  title: {
    color: 'white',
    fontSize: 46,
    fontFamily: 'MatterBold'
  },
  subtitle: {
    color: 'white',
    fontSize: 24,
    fontFamily: 'Matter'
  }
})
export default styles
