import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    width: '100%'
  },
  img: {
    marginTop: '40px'
  }
}))
