import name from './name'

const selectSliceData = state => state[name]

const selectTeamInformation = state => selectSliceData(state).teamInformation
const selectTeam = state => selectSliceData(state).team
const selectMembers = state => selectSliceData(state).members
const selectStatus = state => selectSliceData(state).status
const selectTeamId = state => selectSliceData(state).teamId
const selectTeamChartData = state => selectSliceData(state).teamChartData
const selectTeamChartDataError = state => selectSliceData(state).teamChartDataError
//
export {
  selectTeamInformation,
  selectTeam,
  selectMembers,
  selectStatus,
  selectTeamId,
  selectTeamChartData,
  selectTeamChartDataError
}
