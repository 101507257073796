import React from 'react'
import {
  Dialog,
  Typography,
  Button,
  FormControl,
  TextField
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useStyles } from './style'

const AddTeamMemberDialog = ({
  open,
  handleCancel,
  handleAdd,
  handleSend,
  register,
  membersCount,
  teamMembersError
}) => {
  const classes = useStyles()
  const membersArray = Array.from(Array(membersCount).keys())

  return (
    <Dialog open={open}>
      <div className={classes.root}>
        <Typography variant='h3' className={classes.header}>Add team members</Typography>
        <Typography className={classes.text}>Enter email address. (Maximum of 20 members per team, including you.)</Typography>
        <Typography className={classes.text}>When you click Send invite, the person will receive an email inviting them to join the team and complete the review.</Typography>
        <Typography className={classes.text}>If the invite is sent after the team review has been closed, they can still complete their review, but their results won't be included in the team aggregate.</Typography>
        <form>
          {membersArray.map((count, idx) => (
            <FormControl key={idx} className={classes.formControlSmall} fullWidth>
              <TextField
                label='Enter email address'
                variant='outlined'
                name={`member-${idx}`}
                type='email'
                inputRef={register}
                inputPros={{ 'aria-label': `Team member ${count}` }}
              />
            </FormControl>
          ))}
        </form>
        <Button
          variant='text'
          startIcon={<AddIcon />}
          color='primary'
          onClick={handleAdd}
          disabled={teamMembersError.length}
          className='Team_Edit_Modal_Add_Email'
        >
          Add another email address
        </Button>
        {teamMembersError.length > 0 && (
          <Typography className={classes.errorText}>{teamMembersError}</Typography>
        )}
        <div className={classes.buttons}>
          <Button className='Team_Edit_Modal_Cancel' variant='outlined' color='secondary' onClick={handleCancel}>Cancel</Button>
          <Button className={`${classes.rightButton} Team_Edit_Modal_Send_Invite`} variant='contained' color='secondary' onClick={handleSend}>Send invite</Button>
        </div>
      </div>
    </Dialog>
  )
}

export default AddTeamMemberDialog
