import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  main: {
    marginTop: '64px',
    padding: '0px 100px',
    // marginLeft: '254px',
    transition: 'all 0.25s ease',
    '@media (min-width: 1536px)': {
      padding: '35px 100px'
    },
    '@media (max-width: 1200px)': {
      padding: '0px 48px',
      marginTop: '100px'
    }
  },
  mainMD: {
    padding: '24px',
    transition: 'all 0.25s ease-in-out',
    marginTop: '64px',
    marginLeft: 0
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& img': {
      marginBottom: '150px'
    }
  },
  link: {
    fontSize: '12px',
    color: theme.palette.secondary.main,
    fontWeight: 400,
    cursor: 'pointer',
    flex: 1,
    textAlign: 'start',
    margin: 'auto 0'
  },
  dialogFormBox: {
  },
  description: {
    margin: '8px 0'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: '#006fb7',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'all 0.25s ease-in-out'
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '242px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    '&:hover': {
      backgroundColor: '#024e95'
    },
    transition: 'all 0.25s ease-in-out'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  },
  tab: {
    textTransform: 'inherit'
  },
  tabSelected: {
    textTransform: 'inherit',
    color: '#006FB7',
    fontWeight: 700
  },
  subscription: {
    display: 'flex',
    marginBottom: '24px',
    marginTop: '24px'
  },
  subscriptionBox: {
    flex: 1
  },
  li: {
    paddingLeft: 0
  },
  liIcon: {
    minWidth: '36px'
  },
  hr: {
    marginTop: 0,
    marginBottom: '64px',
    color: '#EEEFF1'
  }
}))
