import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    padding: '48px',
    marginTop: '40px',
    height: '100%',
    transition: 'margin-left 0.25s ease-in-out',
    marginLeft: '296px'
  },
  mainMD: {
    padding: '24px 48px',
    transition: 'margin-left 0.25s ease-in-out',
    marginTop: '64px',
    height: '100%',
    width: '100%'
  },
  mainXL: {
    marginLeft: '368px'
  },
  mainXLCollapsed: {
    marginLeft: '72px'
  },
  button: {
    marginBottom: 16
  },
  introText: {
    fontSize: 16.5,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginBottom: 20
  },
  bodyText: {
    fontSize: 16.5,
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  },
  removeLink: {
    textDecoration: 'underline',
    color: '#006FB7',
    cursor: 'pointer'
  },
  disabledRemoveLink: {
    textDecoration: 'underline',
    color: 'rgba(37, 55, 70, 0.38)'
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  collapseIcon: {
    '&.MuiSvgIcon-root': {
      fill: 'white'
    }
  },
  collapseBtn: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '282px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },
  collapseBtnXL: {
    left: '354px'
  },
  collapseBtnHide: {
    zIndex: '10 !important',
    position: 'absolute',
    top: '80px',
    left: '1px',
    backgroundColor: '#006fb7',
    borderRadius: '50%',
    padding: '2px',
    transition: 'left 0.25s ease-in-out',
    '&:hover': {
      backgroundColor: '#024e95'
    }
  },
  collapseBtnHideXL: {
    left: '73px'
  }
}))
