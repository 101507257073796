import React, { useEffect } from 'react'
import { useEvent } from '@emerald-works/react-event-bus-client'
import { surveySlice } from '../../reducers'
import { useSelector } from 'react-redux'
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  TextField,
  Button,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { LPBLink } from '../../components'
import { useForm } from 'react-hook-form'
import { validateEmail } from '../../libs/formUtils'

const ReportDialog = ({ open, handleClose }) => {
  const [formErrors, setFormErrors] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const emailSaved = useSelector(surveySlice.selectors.selectUserEmailSaved)
  const [saveReportDownload] = useEvent([surveySlice.eventBus.saveReportDownload])

  const { register, watch, reset } = useForm({
    defaultValues: {
      email: ''
    }
  })

  const handleDownload = () => {
    const email = watch('email')
    if (validateEmail(email)) {
      setFormErrors(null)
      setIsLoading(true)
      saveReportDownload.trigger(email)
    } else {
      setFormErrors('You need to provide a valid email address.')
    }
  }

  const handleCloseDialog = () => {
    reset('email')
    handleClose()
  }

  useEffect(() => {
    if (emailSaved) {
      setIsLoading(false)
    }
  }, [emailSaved])

  return (
    <Dialog onClose={handleCloseDialog} open={open}>
      <DialogTitle>
        <Typography variant='h3'>Download a sample results PDF</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          variant='outlined'
          margin='normal'
          label='Enter email address'
          type='text'
          name='email'
          inputRef={register}
          error={formErrors !== null}
          helperText={formErrors}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Typography style={{ fontSize: 12 }}>By clicking 'Download', you agree to Emerald Works’ <LPBLink to='/privacy-policy' target='_blank'>privacy policy</LPBLink> and <LPBLink to='/terms-and-conditions' target='_blank'>terms and conditions</LPBLink>.</Typography>
        <Button
          style={{ padding: '10px 16px', minWidth: 110 }}
          onClick={handleDownload}
          variant='contained'
          color='secondary'
          startIcon={
            isLoading ? <CircularProgress size={14} color='inherir' /> : null
          }
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReportDialog
