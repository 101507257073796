import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
    '& .MuiTableCell-root': {
      borderLeft: '1px solid rgba(224, 224, 224, 1)'
    }
  },
  cell1: {
    width: '12rem'
  },
  cell2: {
    width: '20rem'
  },
  cell3: {
    width: '20rem'
  }
}))
