import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main
    // textDecoration: 'none'
  },
  rootLight: {
    color: 'white',
    fontWeight: 700
  }
}))
