import React from 'react'
import {
  FormControl,
  TextField,
  Grid,
  Button,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import Auth from '@aws-amplify/auth'
import { useStyles } from './style'
import { enableForgotPasswordSend } from '../../libs/formUtils'
import { LPBLink } from '../../components'
import { datadogRum } from '@datadog/browser-rum'

const ForgotPasswordForm = props => {
  const classes = useStyles()
  const { dialogOpen } = props

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      username: ''
    }
  })

  const currentUsername = watch('username')

  const isDisabled = enableForgotPasswordSend(currentUsername)

  const [isAuthenticating, setIsAuthenticating] = React.useState(false)
  // eslint-disable-next-line no-unused-vars
  const [authenticationFeedback, setAuthenticationFeedback] = React.useState(
    false
  )

  const onSubmit = async ({ username }) => {
    setIsAuthenticating(true)
    try {
      await Auth.forgotPassword(username)
    } catch (e) {
      datadogRum.addError(e)
      console.log(e)
    }
    dialogOpen(currentUsername)
    setIsAuthenticating(false)
  }

  return (
    <Grid container justify='center' alignItems='center'>
      <Grid item xs={1} />
      <Grid item xs={10}>
        <Typography data-test='authPageHeader' variant='h1'>
          Forgotten your password?
        </Typography>
        <Typography variant='body1' style={{ marginBottom: 24 }}>
          Enter your registered email and we'll send you a code to reset your
          password.
        </Typography>
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.formInput}>
            <TextField
              data-test='emailField'
              label='Organisation email'
              variant='outlined'
              name='username'
              type='email'
              inputRef={register}
              required
              error={authenticationFeedback.error}
              helperText={authenticationFeedback.message}
            />
          </FormControl>
          {!isAuthenticating
            ? (
              <Button
                id='btn-forgot-password'
                className={classes.primaryButton}
                data-test='btn-reset'
                type='submit'
                variant='contained'
                color='secondary'
                disabled={isDisabled}
              >
                SEND RESET CODE
              </Button>
              )
            : (<CircularProgress />)}
          <p className={classes.loginText}>
            Go back to{' '}
            <LPBLink data-test='lnk-forgotPageLogin' to='/login'>
              log in.
            </LPBLink>
          </p>
        </form>
      </Grid>
      <Grid item xs={1} />
    </Grid>
  )
}

export default ForgotPasswordForm
