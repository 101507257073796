import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  main: {
    marginTop: '64px',
    padding: '0px 100px',
    // marginLeft: '254px',
    transition: '0.25s all ease',
    '@media (min-width: 1536px)': {
      padding: '70px 100px'
    },
    '@media (max-width: 1200px)': {
      padding: '0px 48px',
      marginTop: '100px'
    }
  },
  mainMD: {
    padding: '24px',
    transition: 'all 0.25s ease-in-out',
    marginTop: '64px',
    marginLeft: 0
  },
  paddedText: {
    paddingLeft: 40
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'

  },
  image: {
    width: '100%',
    marginTop: '40px'
  },
  buttons: {
    display: 'flex',
    marginTop: 40
  },
  button: {
    marginRight: 24
  },
  cleaning: {
    color: '#FFF'
  }
}))
