import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  cardButton: {
    display: 'block',
    margin: '0 0 0 auto'
  },
  formControl: {
    marginBottom: '40px'
  }
}))
