import React from 'react'
import {
  FormControl,
  TextField,
  Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useStyles } from './style'
import { useSession } from '@emerald-works/react-auth'
import { userSlice } from '../../../../reducers'
import { useSelector } from 'react-redux'
import { useEvent } from '@emerald-works/react-event-bus-client'
const UserForm = () => {
  const classes = useStyles()
  const { user } = useSession()
  const userData = useSelector(userSlice.selectors.selectUserData)
  const [devUpdateUser] = useEvent([userSlice.eventBus.devUpdateUser])
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      email: user && user.attributes.email,
      premium: userData && (userData.premium || 'NOT PREMIUM'),
      teams: (userData && userData.teams && userData.teams.length) ? userData?.teams.reduce((a, b) => a + ',' + b) : ''
    }
  })

  const onSubmit = async () => {
    devUpdateUser.trigger({ premium: watch('premium'), teams: watch('teams') })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
      <FormControl fullWidth>
        <TextField
          disabled
          margin='normal'
          label='Organisation Email'
          variant='outlined'
          name='email'
          type='email'
          inputRef={register}
          inputProps={{ 'aria-label': 'Organisational email' }}
          data-test='emailField'
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          margin='normal'
          label='Premium'
          variant='outlined'
          name='premium'
          type='text'
          inputRef={register}
          inputProps={{ 'aria-label': 'Premium' }}
          data-test='premiumField'
          helperText={'leave empty for regular account; "team" for premium account'}
        />
      </FormControl>
      <FormControl fullWidth>
        <TextField
          margin='normal'
          label='Teams'
          variant='outlined'
          name='teams'
          type='text'
          inputRef={register}
          inputProps={{ 'aria-label': 'Teams' }}
          data-test='teamsField'
          helperText='enter team ids separated by commas'
        />
      </FormControl>
      <div>
        <Button
          className={classes.primaryButton}
          type='submit'
          variant='contained'
          data-test='btn-save'
        >
          Update user table
        </Button>
      </div>
    </form>
  )
}

export default UserForm
