import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  componentsCards: {
    display: 'flex',
    height: '70%',
    flexDirection: 'row',
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5,
    marginTop: 5
  },
  componentsColumn: {
    display: 'flex',
    width: '50%',
    flexDirection: 'column',
    paddingTop: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingBottom: 5
  }
})
export default styles
