import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    // padding: '0 12px'
  },
  tabs: {
    borderBottom: '1px solid rgba(52, 51, 55, 0.12)'
  },
  tab: {
    minWidth: '140px'
  },
  introText: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px'
  }
}))
