import React from 'react'
import { Document } from '@react-pdf/renderer'
// import styles from './style'
import {
  AlignPage,
  ComponentsCoverPage,
  CoverPage,
  EmployeePage,
  EngagePage,
  EnvironmentPage,
  InsightsPage1,
  InsightsPage2,
  IntroPage,
  ScorePage,
  StagePage1,
  StagePage2,
  TeamPage,
  TrendsPage1,
  TrendsPage2,
  ReadingPage,
  FinalPage,
  ImpactPage
} from './pages'

const ReportPDF = ({ scoresChartData, componentsChartData, profile, behaviours, stage, readingLinks }) => {
  const currentSeason = scoresChartData[0].values[0].date
  return (
    <Document>
      <CoverPage currentSeason={currentSeason} />
      <IntroPage />
      <ScorePage scoresChartData={scoresChartData} currentSeason={currentSeason} />
      <ComponentsCoverPage profile={profile} componentsChartData={componentsChartData} currentSeason={currentSeason} />
      <AlignPage scores={componentsChartData.Align} behaviours={behaviours.Align} season={currentSeason} />
      <EmployeePage scores={componentsChartData.Listen} behaviours={behaviours.Listen} season={currentSeason} />
      <EnvironmentPage scores={componentsChartData.Influence} behaviours={behaviours.Influence} season={currentSeason} />
      <TeamPage scores={componentsChartData.Enable} behaviours={behaviours.Enable} season={currentSeason} />
      <EngagePage scores={componentsChartData.Engage} behaviours={behaviours.Engage} season={currentSeason} />
      <ImpactPage scores={componentsChartData.Improve} behaviours={behaviours.Improve} season={currentSeason} />
      <StagePage1 />
      <StagePage2 stage={stage} />
      <InsightsPage1 stage={stage} />
      <InsightsPage2 stage={stage} />
      <TrendsPage1 />
      <TrendsPage2 />
      <ReadingPage readingLinks={readingLinks} />
      <FinalPage />
    </Document>
  )
}
export default ReportPDF
