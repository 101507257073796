import React, { useRef } from 'react'
import { Typography } from '@material-ui/core'
import { useStyles } from './style'

const ChartStage = ({ id = null, stage, expanded, title, points }) => {
  const containerRef = useRef(null)
  const classes = useStyles()
  const stages = ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4']
  const titles = [
    'Transactional L&D intervention',
    'Implementing L&D programmes',
    'Proactive talent and performance strategy',
    'High-impact learning culture'
  ]
  const impacts = [
    '(0–49 - Surface impact)',
    '(50–62 - Low impact)',
    '(63–72 - Moderate impact)',
    '(73–100 - Deep impact)'
  ]

  if (expanded) {
    return (
      <div ref={containerRef} className={stage > 1 ? classes.bigRoot : classes.bigRoot1} id={id}>
        <div className={classes.stagesBox}>
          {stages.map((name, idx) => {
            const iterationStage = idx + 1
            if (stage === iterationStage) {
              const boxStyle =
                iterationStage !== 1
                  ? `${classes[`bigStep${iterationStage}`]} ${
                      classes.currentStage
                    }`
                  : classes.currentStage
              return (
                <div key={idx} className={boxStyle}>
                  <img
                    className={classes.stageImage}
                    src='/img/stage.png'
                    alt=''
                    width='90px'
                    height='105px'
                  />
                  <span className={classes.verticalBigText}>
                    <Typography
                      variant='subtitle1'
                      data-test='maturityStage'
                    >
                      {name}: {titles[idx]}
                    </Typography>
                    <Typography className={classes.currentImpactText}>
                      {impacts[idx]}
                    </Typography>
                  </span>
                </div>
              )
            } else {
              const boxStyle =
                iterationStage !== 1
                  ? `${classes[`bigStep${iterationStage}`]} ${
                      classes.verticalText
                    }`
                  : classes.verticalText
              return (
                <div key={idx} className={boxStyle}>
                  <Typography
                    variant='body1'
                    className={classes.stageDesc}
                    data-test='maturityStage'
                  >
                    {name}: {titles[idx]}
                  </Typography>
                  <Typography variant='caption'>
                    {impacts[idx]}
                  </Typography>
                </div>
              )
            }
          })}
        </div>

        {/* <div className={classes.stageSteps}>
          <div className={classes.firstStageStep}>
            <Typography variant='subtitle1' color='inherit'>
              Surface impact
            </Typography>
            <Typography variant='body2' color='inherit'>
              (Stage 1: 0-49)
            </Typography>
          </div>
          <div className={classes.secondStageStep}>
            <Typography variant='subtitle1' color='inherit'>
              Low
            </Typography>
            <Typography variant='body2' color='inherit'>
              (Stage 2: 50-62)
            </Typography>
          </div>
          <div className={classes.thirdStageStep}>
            <Typography variant='subtitle1' color='inherit'>
              Moderate
            </Typography>
            <Typography variant='body2' color='inherit'>
              (Stage 3: 63-72)
            </Typography>
          </div>
          <div className={classes.fourthStageStep}>
            <Typography variant='subtitle1' color='inherit'>
              Deep impact
            </Typography>
            <Typography variant='body2' color='inherit'>
              (Stage 4: 73-100)
            </Typography>
          </div>
        </div> */}
      </div>
    )
  } else {
    return (
      <div className={classes.stagesBox} id={id}>
        {stages.map((name, idx) => {
          const iterationStage = idx + 1

          if (stage === iterationStage) {
            const boxStyle =
              iterationStage !== 1
                ? `${classes[`step${iterationStage}`]} ${classes.currentStage}`
                : classes.currentStage
            return (
              <div key={idx} className={boxStyle}>
                <img
                  className={classes.stageImage}
                  src='/img/stage.png'
                  alt=''
                  width='75px'
                  height='90px'
                />
                <Typography className={classes.currentStageText}>{name}</Typography>
              </div>
            )
          } else {
            const boxStyle =
              iterationStage !== 1
                ? `${classes[`step${iterationStage}`]} ${classes.stage}`
                : classes.stage
            return (
              <div key={idx} className={boxStyle}>
                <Typography className={classes.stageText}>{name}</Typography>
              </div>
            )
          }
        })}
      </div>
    )
  }
}

export default ChartStage
