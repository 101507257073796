import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    margin: '4px 0'
  },
  accordionRoot: {
    height: '100%',
    padding: '8px 0'
  },
  accordionContent: {
    alignItems: 'center'
  },
  titleText: {
    marginLeft: '15px'
  },
  description: {
    marginLeft: '52px',
    flex: 1
  },
  imgBox: {
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      objectFit: 'contain'
    }
  }
}))
