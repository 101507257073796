import React from 'react'
import { Page, View, Text, Image } from '@react-pdf/renderer'
import { PDFFooter } from '../../components'
import styles from './style'
import globalStyles from '../../style'

const StagePage2 = ({ stage }) => {
  const dataURL = localStorage.getItem('percentagesChartData')
  const stageLineChartDataURL = localStorage.getItem('linearStageChartData')
  const stages = {
    1: {
      head: 'Stage 1: Transactional L&D intervention',
      points: [
        'You focus on efficiency',
        'Your L&D strategy is responsive, but would benefit from more detailed and informed planning',
        'You aren\'t getting the most out of your data'
      ]
    },
    2: {
      head: 'Stage 2: Implementing L&D programmes',
      points: [
        'You focus on technology, but your impact on learning culture is limited',
        'You have good business awareness, but your L&D decisions are often made in isolation'
      ]
    },
    3: {
      head: 'Stage 3: Proactive talent and performance strategy',
      points: [
        'You focus on outcomes',
        'You drive and integrate work and learning, but senior figures don’t always consider L&D a priority',
        'You leverage data to inform decisions and effect change'
      ]
    },
    4: {
      head: 'Stage 4: High-impact learning culture',
      points: [
        'Multiple stakeholders share a focus on outcomes',
        'You draw on analytics to inform and fine-tune your L&D strategy'
      ]
    }
  }
  return (
    <Page size='A4' orientation='landscape'>
      <View style={globalStyles.pageView}>
        <View style={styles.textContainer}>
          <Text style={globalStyles.introText}>You are at:</Text>
          <Text style={globalStyles.subheading}>{stages[stage].head}</Text>
          <View style={styles.list}>
            {stages[stage].points.map((point, index) => (
              <Text key={index} style={globalStyles.body}>• {point}</Text>))}
          </View>
        </View>
        <View style={styles.graphContainer}>
          <View style={{ width: '60%' }}>
            <Text style={[globalStyles.subheading, { marginBottom: 5 }]}>Overall participant trends</Text>
            {dataURL ? <Image src={stageLineChartDataURL} style={{ objectFit: 'contain' }} /> : <Text>line chart placeholder</Text>}
          </View>
          <View style={{ width: '40%' }}>
            <Text style={[globalStyles.subheading, { marginBottom: 5 }]}>Overall participants by stage</Text>
            {dataURL && <Image src={dataURL} style={{ objectFit: 'contain' }} />}
          </View>
        </View>
        <PDFFooter pageNumber={12} />
      </View>

    </Page>
  )
}
export default StagePage2
