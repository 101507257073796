import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'

import { AppToolbar, LPBLink } from '../../components'
import { useStyles } from './style'

const EthicalStandards = () => {
  const classes = useStyles()

  const ScrollToTopOnMount = () => {
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])

    return null
  }

  return (
    <div>
      <ScrollToTopOnMount />
      <AppToolbar />
      <Grid
        className={classes.root}
        container
        direction='column'
        justify='space-between'
        alignItems='flex-start'
      >
        <Grid item xs={8}>
          <Typography className={classes.headTitle} variant='h1'>
            Ethical Standards of Research
          </Typography>
          <Typography className={classes.content} variant='body1'>
            Emerald Works Insights Team
          </Typography>
        </Grid>
        <Grid className={classes.boxIntro} item xs={8}>
          <Typography variant='h3'>
            Introduction
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            In this document, we make three broad commitments to ensure our research is ethical. We commit to supporting our community, to being honest and to respecting the rights of the community and research participants. These commitments were chosen with reference to the ethical standards of leading research bodies (e.g., Pew Research Centre), ethical guidances bodies (e.g., Ethical Standards Commissioner) and the Insights Team’s own reflections.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            In this statement, we also aim to be transparent about our use of data and the business motivations for our research. We have tried to do this in the form of a graphic, which you can find at the end of the document.
          </Typography>
        </Grid>
        <Grid className={classes.boxIntro} item xs={8}>
          <Typography variant='h3'>
            Our commitments
          </Typography>
          <Typography style={{ textDecoration: 'underline' }} className={classes.highlight} variant='body1'>
            Community
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <b>We commit to supporting the L&OD community through our research.</b>{' '}For us, this primarily means <b>providing timely statistics to community members</b>, free of charge. In addition, <b>we aid in the development of services intended for use by the community</b>. Some of these services are also free of charge.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <b>We commit to getting our findings to those who need it most.</b>{' '}For example, one step we take to facilitate this is providing research reports for free. We also contribute to webinars and other community events.
          </Typography>
          <Typography style={{ textDecoration: 'underline' }} className={classes.highlight} variant='body1'>
            Integrity
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <b>We commit to honesty and rigour.</b>{' '}We aim to use robust, statistically sound analytical techniques, while articulating easily digestible insights for the benefit of our community. Crucially, <b>what you see in a report is what we found in the data</b>. That being said, the sections of reports describing research methodology will usually be simplified to conform to the interests of our community. And we may avoid overlay complex analysis for ease of communication throughout a report. These concerns are not as prominent in our internal research.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Given that this research unit exists as part of a company that provides services relevant to our research, <b>it is important for us be transparent about conflict of interest</b>. Our research is not a promotional tool for Emerald Works. If we find something that demonstrates a flaw in one of our products, we will not hide this information. It would be taken as an opportunity to improve the product. Nevertheless, to avoid our research evolving into active marketing, <b>we will not make any direct endorsements of, or references to, paid Emerald Works products or services in our research reports</b>. The only exception being that we may refer to an Emerald Works service or product that has both free and paid version.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            Finally, regarding integrity, <b>we commit yo admitting our mistakes, and correcting them where possible</b>.
          </Typography>
          <Typography style={{ textDecoration: 'underline' }} className={classes.highlight} variant='body1'>
            Respect
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            <b>We commit to protecting the rights of our research participants</b> (who are often users of Emerald Work’s products). Specifically, the right to privacy, and the right to autonomy (i.e., to withdraw their data from our databases).
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            We collect data only for two reasons: to improve our services and products, or to contribute to the wider community. As an example of the former, we may use customer churn dat and exit surveys to determine how we can provide future users with a better experience. As an example of our community work, we often produce research reports on L&OD using data from our Learning Performance Benchmark, which itself is a useful tool for our community.
          </Typography>
          <Typography className={classes.paragraph} variant='body1'>
            If you volunteer to participate in research projects being undertaken by us or our group companies, this will involve us processing your personal data. Typically, this will be personal data such as your name, email address and your job role. Any personal data you share for the pupose of your participation will only be accessible to members of the team who are involved in research activities. The data we collect, for example, from the Learning Performance Benchmark, is stored securely, and only aggregated, anonymised data is shared internally with other parties within Emerald Works. Similarly, we never share data collected by other means (e.g., web analytics) outside company. We have no reason to do this, and we do not aim to adopt business practices for which this would be beneficial.{' '}
            <b>All data collection, processing and storage is GDPR compliant and is done so in accordance with Emerald Work’s privacy policy available at{' '}
              <LPBLink to='/privacy-policy' target='_blank' rel='noopener noreferrer'>privacy policy page</LPBLink>
            </b>.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default EthicalStandards
