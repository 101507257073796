import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#253746',
    display: 'flex',
    flexDirection: 'column',
    padding: '40px 24px',
    minWidth: '12.5rem',
    minHeight: '17.5rem',
    margin: '32px 0',
    justifyContent: 'center',
    alignItems: 'center'
  },
  text: {
    color: 'white',
    textAlign: 'center',
    maxWidth: '12.25rem'
  },
  scoreBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '5px 0'
  },
  scorePositive: {
    fill: '#7BC340 !important'
  },
  scoreNegative: {
    fill: '#F44336 !important'
  },
  score: {
    color: 'white',
    fontWeight: 700,
    margin: '0 8px'
  }
}))
