import * as actions from './slice'

export const saveTeamInformation = {
  eventName: 'saveTeamInformation',
  onSuccess: [{ redux: true, action: actions.setTeamInfo }],
  onError: [{ redux: true, action: actions.setTeamInfoError }]
}

export const getTeam = {
  eventName: 'getTeam',
  onSuccess: [{ redux: true, action: actions.setTeam }],
  onError: [{ redux: true, action: actions.setTeamError }]
}

export const removeTeamMember = {
  eventName: 'removeTeamMember',
  onSuccess: [{ redux: true, action: actions.setTeam }],
  onError: [{ redux: true, action: actions.removeTeamMemberError }]
}

export const closeTeamReview = {
  eventName: 'closeTeamReview',
  onSuccess: [{ redux: true, action: actions.setTeam }],
  onError: [{ redux: true, action: actions.closeTeamReviewError }]
}

export const addTeamMembers = {
  eventName: 'addTeamMembers',
  onSuccess: [{ redux: true, action: actions.setTeam }],
  onError: [{ redux: true, action: actions.addTeamMembersError }]
}

export const getTeamScoresChartData = {
  eventName: 'getTeamScoresChartData',
  onSuccess: [{ redux: true, action: actions.setTeamScoresChartData }],
  onError: [{ redux: true, action: actions.setTeamScoresChartDataError }]
}
