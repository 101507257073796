import React, { useRef, useLayoutEffect } from 'react'
import { drawChart } from './draw'
import './chart.css'
import { useStyles } from './style'
import { Grid, Button, SvgIcon, Tooltip, useMediaQuery } from '@material-ui/core'
import { ReactComponent as FirstBulletIcon } from './circular-label-1.svg'
import { ReactComponent as SecondBulletIcon } from './circular-label-2.svg'
import { ReactComponent as ThirdBulletIcon } from './circular-label-3.svg'

const InfoTooltip = () => {
  const classes = useStyles()
  return (
    <Tooltip className={classes.tooltip} title='Based on overall responses from the private, public and not-for-profit sectors.' aria-label='Based on overall responses from the private, public and not-for-profit sectors.'>
      <img src='/img/info.png' alt='info' width='20' height='20' />
    </Tooltip>
  )
}

const ScoresChart = ({ data, maxValue, isSmall, error, animate = true }) => {
  const isXSScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isMDScreen = useMediaQuery((theme) => theme.breakpoints.between('sm', 'md'))
  const isLGScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'))
  let screenSize = (isXSScreen && 'xs') || (isMDScreen && 'md') || (isLGScreen && 'lg')
  if (!animate) screenSize = 'lg'
  const classes = useStyles()
  const ref = useRef()

  const chartData = data

  // useLayoutEffect(() => {
  //   if (ref.current) {
  //     // isSmall
  //     //   ? setDimension(Math.round(ref.current.offsetWidth - 60))
  //     setDimension(Math.round(ref.current.offsetWidth))
  //   }
  // }, [isSmall])

  useLayoutEffect(() => {
    if (ref.current && chartData) {
      drawChart(chartData, ref, maxValue, isSmall, screenSize, animate)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize, error])

  return (
    <div
      ref={ref}
      className={!animate ? classes.svgContainerForPdf : (isSmall ? classes.svgContainer : classes.bigSvgContainer)}
    />
  )
}

const CircularChart = ({ id = null, isSummary = false, animate = true, scores, error, maxScore, industry, handleClick, currentSeason, isTeam }) => {
  const reqTypes = isTeam ? ['team', 'overall', 'industry'] : ['individual', 'overall', 'industry']
  const classes = useStyles()

  let circularScoreData = scores
    .filter(score => reqTypes.indexOf(score.type) > -1)
    .map(score => {
      const values = currentSeason ? score.values.find(value => value.date === currentSeason) : score.values[0]
      return {
        color: score.color,
        value: values.score.toFixed(2)
      }
    })
  circularScoreData = [
    ...circularScoreData,
    {
      value: 100,
      color: '#EEEFF1'
    }
  ]
  const userScores = scores[0] ? scores[0].values : []
  const lastSeason = userScores.length ? userScores[0].date - 1 : 2020
  const hasPreviousScore = userScores.length > 1 && userScores[0].date > userScores[1].date
  const scoreDifference =
    hasPreviousScore
      ? (userScores[0].score - userScores[1].score).toFixed(2)
      : userScores.length
        ? userScores[0].score.toFixed(2)
        : 0
  const scoreSign = scoreDifference > 0 ? '+' : ''
  let benchmarkScore = scores[1] && currentSeason ? scores[1].values.find(value => value.date === currentSeason).score : 0
  if (benchmarkScore === 0) {
    benchmarkScore = scores[1] ? scores[1].values[0].score : 0
  }
  const labelColors = ['#FF8200', '#006FB7', '#00A9E0']
  //  const scoreDiff = scores[0] - scores[1]

  return isSummary
    ? (
      <Grid container direction='row' id={id}>
        <Grid item xs={6} md={12} lg={6} xl={6}>
          <ScoresChart data={circularScoreData} maxValue={maxScore} isSmall={isSummary} error={error} animate={animate} />
        </Grid>
        <Grid item xs={6} md={12} lg={6} xl={6} className={classes.chartLabelsContainer}>
          <div className={classes.smallChartLabelBox}>
            {labelColors.map((_, idx) => {
              return idx === 0
                ? (
                  <span key={idx} className={classes.chartLabel}>
                    <SvgIcon classes={{ root: classes.iconLabel }} component={FirstBulletIcon} />
                    {
                      isTeam
                        ? <p className={classes.charLabelText}>Team aggregated score: {scoreDifference}</p>
                        : hasPreviousScore
                          ? <p className={classes.charLabelText}>Your score is <b>{scoreSign}{scoreDifference} points</b> from {lastSeason}</p>
                          : <p className={classes.charLabelText}>Your score: {scoreDifference}</p>
                    }
                  </span>
                  )
                : idx === 1
                  ? (
                    <span key={idx} className={classes.chartLabel}>
                      <SvgIcon classes={{ root: classes.iconLabel }} component={SecondBulletIcon} />
                      <p className={classes.charLabelText}>Benchmark: {benchmarkScore.toFixed(2)}</p>
                      <InfoTooltip />
                    </span>
                    )
                  : (
                    <span key={idx} className={classes.chartLabel}>
                      <SvgIcon classes={{ root: classes.iconLabel }} component={ThirdBulletIcon} />
                      <p className={classes.charLabelText}>{`Your industry (${industry})`}</p>
                    </span>
                    )
            })}
            <Button
              className={classes.button}
              color='secondary'
              variant='contained'
              onClick={handleClick}
              data-test='btn-Explore'
            >
              Explore your score
            </Button>
          </div>
        </Grid>
      </Grid>
      )
    : (
      <Grid className={classes.root} container direction='column' id={id}>
        <Grid item>
          <ScoresChart data={circularScoreData} maxValue={maxScore} animate={animate} />
        </Grid>
        <Grid item>
          <div className={!animate ? classes.chartLabelBoxForPdf : classes.chartLabelBox}>
            {labelColors.map((fill, idx) => {
              return idx === 0
                ? (
                  <span key={idx} className={classes.chartLabel}>
                    <SvgIcon classes={{ root: classes.iconLabel }} component={FirstBulletIcon} />
                    {
                      isTeam
                        ? <p className={classes.charLabelText}>Team aggregated score: {scoreDifference}</p>
                        : hasPreviousScore
                          ? <p className={classes.charLabelText}>Your score is <b>{scoreSign}{scoreDifference} points</b> from {lastSeason}</p>
                          : <p className={classes.charLabelText}>Your score: {scoreDifference}</p>
                    }
                  </span>
                  )
                : idx === 1
                  ? (
                    <span key={idx} className={classes.chartLabel}>
                      <SvgIcon classes={{ root: classes.iconLabel }} component={SecondBulletIcon} />
                      <p className={classes.charLabelText}>Benchmark: {circularScoreData[idx].value}</p>
                    </span>
                    )
                  : (
                    <span key={idx} className={classes.chartLabel}>
                      <SvgIcon classes={{ root: classes.iconLabel }} component={ThirdBulletIcon} />
                      <p className={classes.charLabelText}>{`Your industry (${industry})`}: {circularScoreData[idx].value}</p>
                    </span>
                    )
            })}
          </div>
        </Grid>
      </Grid>
      )
}

export default CircularChart
