import name from './name'

const selectSliceData = state => state[name]

const selectUserData = state => selectSliceData(state).userData
const selectMenuLevel = state => selectSliceData(state).menuLevel
const selectActiveMenuSection = state => selectSliceData(state).activeMenuSection
const selectFeatureToggles = state => selectSliceData(state).featureToggles
const selectUserUpdateSuccess = state => selectSliceData(state).updateUserSuccess
const selectSubscriptionValidated = state => selectSliceData(state).subscriptionValidated
const selectSubscriptionError = state => selectSliceData(state).subscriptionError
const selectInvoices = state => selectSliceData(state).invoices
const selectInvoicePDF = state => selectSliceData(state).invoicePDF
export {
  selectUserData,
  selectMenuLevel,
  selectActiveMenuSection,
  selectFeatureToggles,
  selectUserUpdateSuccess,
  selectSubscriptionValidated,
  selectSubscriptionError,
  selectInvoices,
  selectInvoicePDF
}
