import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { TextTooltip, Dropdown } from '../index'
import { useStyles } from './style'

const SurveyStepHeader = ({ header }) => {
  const classes = useStyles()

  return header
    ? (
      <Grid container spacing={3}>

        {header.img && (
          <>
            <Grid item xs={6}>
              <Typography className={classes.sectionTitle} variant={header.h1 ? 'h1' : 'h2'}>{header.title}</Typography>
              {header.description && <Typography style={{ whiteSpace: 'pre-line' }} className={classes.introText}>{header.description}</Typography>}
              {header.tip && (
                <TextTooltip
                  text={header.tip.title}
                  tooltipText={header.tip.text}
                />
              )}
              {header.dropdown && (
                <Dropdown
                  text={header.dropdown.text ||
                  'tests'}
                />
              )}
            </Grid>
            <Grid item xs={2} />
          </>
        )}
        {!header.img && (
          <>
            <Grid item xs={10}>
              <Typography className={classes.sectionTitle} variant='h2'>{header.title}</Typography>
              {header.description && <Typography style={{ whiteSpace: 'pre-line' }} className={classes.introText}>{header.description}</Typography>}
              {header.tip && (
                <TextTooltip
                  text={header.tip.title}
                  tooltipText={header.tip.text}
                />
              )}
              {header.dropdown && (
                <Dropdown
                  text={header.dropdown.text ||
                  'tests'}
                />
              )}
            </Grid>
            <Grid item xs={2} />
          </>
        )}
        {header.img && (
          <Grid item xs={4}>
            <div className={classes.img}>
              <img src={header.img} alt='' />
            </div>
          </Grid>
        )}

      </Grid>
      )
    : null
}

export default SurveyStepHeader
