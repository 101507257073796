import React, { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useEvent, useEventsOnViewLoad } from '@emerald-works/react-event-bus-client'
import { Grid, Button, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, IconButton } from '@material-ui/core'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import { Skeleton } from '@material-ui/lab'
import { useStyles } from './style'
import {
  AppToolbar,
  RemoveTeamMemberDialog,
  CloseTeamReviewDialog,
  AddTeamMemberDialog,
  AddTeamMemberMaximumDialog,
  CloseReviewBlockedDialog,
  NavigationDrawer
} from '../../components'
import { surveySlice, teamSlice, userSlice, dashboardSlice } from '../../reducers'
import { basedURL } from '../../helpers/domain'
import { validateEmail } from '../../libs/formUtils'

const TeamPage = () => {
  const classes = useStyles()
  const dispatcher = useDispatch()
  const hj = window.hj
  const dataLayer = window.dataLayer || []
  const navigate = useNavigate()
  const { register, watch } = useForm({})

  const currentPage = useSelector(dashboardSlice.selectors.selectPage)
  const innerPage = useSelector(dashboardSlice.selectors.selectInnerPage)

  const isXSScreen = useSelector(surveySlice.selectors.selectIsXSScreen)
  const isMDScreen = useSelector(surveySlice.selectors.selectIsMDScreen)
  const isLGScreen = useSelector(surveySlice.selectors.selectIsLGScreen)
  const isXLScreen = isLGScreen

  const teamMembers = useSelector(teamSlice.selectors.selectMembers)
  const teamStatus = useSelector(teamSlice.selectors.selectStatus)
  const teamId = useSelector(teamSlice.selectors.selectTeamId)
  const userData = useSelector(userSlice.selectors.selectUserData)

  const isAdmin = userData?.premium === 'team' || false
  if (!isAdmin) {
    navigate('/not-found')
  }
  const membersEmails = teamMembers.map(member => member.email)

  const [showRemoveMemberDialog, setShowRemoveMemberDialog] = React.useState(false)
  const [showCloseTeamReviewDialog, setShowTeamReviewDialog] = React.useState(false)
  const [showAddMembersDialog, setShowAddMembersDialog] = React.useState(false)
  const [showMaximumDialog, setShowMaximumDialog] = React.useState(false)
  const [showMinimumReviewersDialog, setShowMinimunReviewersDialog] = React.useState(false)
  const [membersCount, setMembersCount] = React.useState(1)
  const [teamMembersError, setTeamMembersError] = React.useState('')
  const [removeMemberInfo, setRemoveMemberInfo] = React.useState({})
  const [removeTeamMember] = useEvent([teamSlice.eventBus.removeTeamMember])
  const [closeTeamReview] = useEvent([teamSlice.eventBus.closeTeamReview])
  const [getTeam] = useEvent([teamSlice.eventBus.getTeam])
  const [addTeamMembers] = useEvent([teamSlice.eventBus.addTeamMembers])
  const [showNavBar, setShowNavBar] = React.useState(isLGScreen)
  const [showFloatBtn, setShowFloatBtn] = React.useState(false)
  const [hideNav, setHideNav] = React.useState(isXLScreen)
  const [ovdHideNav, setOvdHideNav] = React.useState(isXLScreen)

  const openNavBar = () => {
    setShowNavBar(true)
  }
  const closeNavBar = () => {
    setShowNavBar(false)
  }

  const handleNavDrawerHover = () => {
    if (!hideNav) {
      setShowFloatBtn(true)
    } else {
      setOvdHideNav(true)
    }
  }

  const handleContentHover = () => {
    setShowFloatBtn(false)
    setOvdHideNav(false)
  }

  const toggleHideNav = () => {
    setHideNav(!hideNav)
    setShowFloatBtn(true)
  }

  const handleRemoveMember = (userName, userEmail) => {
    setRemoveMemberInfo({ name: userName, email: userEmail })
    setShowRemoveMemberDialog(true)
  }

  const denyRemoveMember = () => {
    setShowRemoveMemberDialog(false)
  }

  const confirmRemoveTeamMember = email => {
    removeTeamMember.trigger(email)
    setShowRemoveMemberDialog(false)
  }

  const handleCloseReview = () => {
    const minCompleters = teamMembers.filter(member => member.status === 'Completed').length >= 3
    if (minCompleters) {
      setShowTeamReviewDialog(true)
    } else {
      setShowMinimunReviewersDialog(true)
    }
  }

  const denyCloseReview = () => {
    setShowTeamReviewDialog(false)
  }

  const confirmCloseTeamReview = () => {
    closeTeamReview.trigger({ baseUrl: window.location.protocol + '//' + basedURL() })
    setShowTeamReviewDialog(false)
  }

  const handleAddTeamMembersClick = () => {
    if (teamMembers.length >= 20) {
      setShowMaximumDialog(true)
    } else {
      setShowAddMembersDialog(true)
    }
  }

  const handleCancelAddMembers = () => {
    setMembersCount(1)
    setShowAddMembersDialog(false)
  }

  const handleAddMembers = () => {
    setMembersCount(membersCount + 1)
  }

  const handleCloseMaximum = () => {
    setShowMaximumDialog(false)
  }

  const handleCloseMinReviewers = () => {
    setShowMinimunReviewersDialog(false)
  }

  const handleSendInvite = () => {
    const formData = watch()

    const validFormEmailKeys = Object.keys(formData).filter(key => key.startsWith(('member-')) && formData[key].length > 0 && validateEmail(formData[key]))
    const validEmails = validFormEmailKeys.map(key => formData[key])
    const usedEmails = validEmails.map(email => membersEmails.indexOf(email)).filter(val => val >= 0)
    if (validEmails.length && usedEmails.length === 0) {
      const teamMembersData = {
        hashId: teamId,
        members: validEmails,
        url: window.location.protocol + '//' + basedURL()
      }
      addTeamMembers.trigger({ data: teamMembersData })
      setShowAddMembersDialog(false)
      setMembersCount(1)
    } else {
      setTeamMembersError("You can't invite existing members")
    }
  }

  const setCurrentPage = useCallback((name) => {
    dispatcher(dashboardSlice.actions.setPage(name))
    dispatcher(dashboardSlice.actions.setInnerPage(''))
    if (name === 'areas') {
      dispatcher(dashboardSlice.actions.setInnerPage('Align'))
    }
    if (name === 'trends') {
      dispatcher(dashboardSlice.actions.setInnerPage('Technology'))
    }
    navigate('/team-dashboard')
  }, [dispatcher, navigate])

  const setInnerPage = useCallback((name) => {
    dispatcher(dashboardSlice.actions.setInnerPage(name))
    navigate('/team-dashboard')
  }, [dispatcher, navigate])

  useEffect(() => {
    if (!hj) return
    hj('stateChange', '/teams')
    dataLayer.push({
      event: 'stateChange',
      attributes: {
        path: '/teams'
      }
    })
  })

  useEffect(() => {
    if (isLGScreen) setShowNavBar(true)
    if (isMDScreen) setShowNavBar(false)
  }, [isLGScreen, isMDScreen])

  useEffect(() => {
    if (membersCount + teamMembers.length === 20) {
      setTeamMembersError('You have reached the maximum of 20 team members')
    } else {
      setTeamMembersError('')
    }
  }, [membersCount, teamMembers])

  useEventsOnViewLoad(() => {
    if (!teamId) {
      getTeam.trigger()
    }
  }, [getTeam])

  const isSaving = getTeam.isWorking || closeTeamReview.isWorking || removeTeamMember.isWorking || addTeamMembers.isWorking

  return (
    <div className={classes.root}>
      <AppToolbar
        isMobileScreen={isMDScreen || isXSScreen}
        navBarOpen={showNavBar}
        openNavBar={openNavBar}
        closeNavBar={closeNavBar}
      />

      <Grid container>
        <Grid container item xs={2}>
          <NavigationDrawer
            activeDashboardLink={currentPage}
            activeInnerLink={innerPage}
            setActiveDashboardLink={setCurrentPage}
            setActiveInnerLink={setInnerPage}
            isLGScreen={isLGScreen}
            showNavBar={showNavBar}
            closeNavBar={closeNavBar}
            hideNav={hideNav}
            ovdHideNav={ovdHideNav}
            onMouseOver={handleNavDrawerHover}
          />
          {((isLGScreen && showFloatBtn) || (isLGScreen && hideNav)) && (
            <IconButton
              aria-label='toggle navigation menu'
              className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.collapseBtn} ${isXLScreen && classes.collapseBtnXL}` : `${classes.collapseBtnHide} ${isXLScreen && classes.collapseBtnHideXL}`}
              onClick={toggleHideNav}
            >
              {
                  !hideNav
                    ? (
                      <KeyboardArrowLeft
                        className={classes.collapseIcon}
                      />
                      )
                    : (
                      <KeyboardArrowRight
                        className={classes.collapseIcon}
                      />
                      )
                }
            </IconButton>
          )}
        </Grid>
        <Grid container>
          <div
            className={isLGScreen && ((!hideNav && !ovdHideNav) || (hideNav && ovdHideNav)) ? `${classes.main} ${isXLScreen && classes.mainXL}` : `${classes.mainMD} ${isXLScreen && classes.mainXLCollapsed}`}
            onMouseOver={handleContentHover}
            id='main'
          >
            <Typography variant='h1'>Current team members</Typography>
            <Grid container spacing={6}>
              <Grid item lg={10} md={12} className={classes.tableGrid}>
                <Typography className={classes.introText}>Close the review to access your collective team results.</Typography>
                <Typography className={classes.bodyText}>You can add or remove team members at any time. However, if you remove a team member after you have closed the review, their results will still show within the aggregated results. (Maximum of 20 members per team, including you.)</Typography>
              </Grid>
              <Grid item lg={2} md={12}>
                <span className={classes.btnContainer}>
                  <Button
                    variant='contained'
                    color='secondary'
                    className={`${classes.button} Team_Close_Review`}
                    onClick={handleCloseReview}
                    disabled={teamStatus !== 'open'}
                  >
                    Close Team Review
                  </Button>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={handleAddTeamMembersClick}
                    className='Team_Edit_Add_Member'
                  >
                    Add team members
                  </Button>
                </span>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table aria-label='members list'>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Name</TableCell>
                        <TableCell>Email address</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>User type</TableCell>
                        <TableCell>Remove</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isSaving && [0, 1, 2, 3, 4].map(key => (
                        <TableRow key={key}>
                          <TableCell>{key + 1}.</TableCell>
                          <TableCell><Skeleton /></TableCell>
                          <TableCell><Skeleton /></TableCell>
                          <TableCell><Skeleton /></TableCell>
                          <TableCell><Skeleton /></TableCell>
                          <TableCell>
                            <span
                              className={classes.disabledRemoveLink}
                            >
                              Remove
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                      {!isSaving && teamMembers.map((member, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{idx + 1}.</TableCell>
                          <TableCell>{member.name}</TableCell>
                          <TableCell>{member.email}</TableCell>
                          <TableCell>{member.status}</TableCell>
                          <TableCell>{member.role === 'owner' ? 'Admin' : 'Team member'}</TableCell>
                          <TableCell>
                            <span
                              className={member.role === 'owner' ? `${classes.disabledRemoveLink} Team_Edit_Remove_Member` : `${classes.removeLink} Team_Edit_Remove_Member`}
                              onClick={member.role !== 'owner' ? () => handleRemoveMember(member.name, member.email) : null}
                            >
                              Remove
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      <RemoveTeamMemberDialog
        open={showRemoveMemberDialog}
        reviewStatus={teamStatus}
        handleClose={denyRemoveMember}
        member={removeMemberInfo}
        handleRemove={confirmRemoveTeamMember}
      />
      <CloseTeamReviewDialog
        open={showCloseTeamReviewDialog}
        handleDeny={denyCloseReview}
        handleClose={confirmCloseTeamReview}
      />
      <AddTeamMemberDialog
        open={showAddMembersDialog}
        handleCancel={handleCancelAddMembers}
        handleAdd={handleAddMembers}
        handleSend={handleSendInvite}
        register={register}
        membersCount={membersCount}
        teamMembersError={teamMembersError}
      />
      <AddTeamMemberMaximumDialog
        open={showMaximumDialog}
        handleOk={handleCloseMaximum}
      />
      <CloseReviewBlockedDialog
        open={showMinimumReviewersDialog}
        handleOk={handleCloseMinReviewers}
      />
    </div>
  )
}

export default TeamPage
