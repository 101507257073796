import name from './name'

const selectSliceData = state => state[name]

const selectPage = state => selectSliceData(state).page
const selectInnerPage = state => selectSliceData(state).innerPage
const selectResponses = state => selectSliceData(state).responses
const selectStepsProgress = state => selectSliceData(state).stepsProgress
const selectPercentageCompleted = state => selectSliceData(state).percentageCompleted
const selectResults = state => selectSliceData(state).results
const selectProfile = state => selectSliceData(state).profile
const selectLatestResults = state => selectSliceData(state).latest
const selectIsSaving = state => selectSliceData(state).isSaving
const selectChartData = state => selectSliceData(state).chartData
const selectChartDataError = state => selectSliceData(state).chartDataError
const selectReportUrl = state => selectSliceData(state).reportUrl
const selectReadings = state => selectSliceData(state).readingsUrl
const selectResultsLoading = state => selectSliceData(state).resultsLoading
const selectCalculatingResults = state => selectSliceData(state).calculatingResults
const selectResponsesError = state => selectSliceData(state).responsesError
const selectSaveError = state => selectSliceData(state).saveError
const selectIsLGScreen = state => selectSliceData(state).isLGScreen
const selectIsMDScreen = state => selectSliceData(state).isMDScreen
const selectIsXSScreen = state => selectSliceData(state).isXSScreen
const selectUserReportUrl = state => selectSliceData(state).userReportUrl
const selectUserReportError = state => selectSliceData(state).userReportError
const selectSignedUrlForReport = state => selectSliceData(state).signedUrlForReport
const selectSignedUrlForReportError = state => selectSliceData(state).signedUrlForReportError
const selectUnansweredProfileQuestions = state => selectSliceData(state).unansweredProfileQuestions
const selectResultsClean = state => selectSliceData(state).resultsCleaned
const selectPDFDownloading = state => selectSliceData(state).PDFDownloading
const selectResultsUpdateSuccess = state => selectSliceData(state).updateResultsSuccess
const selectSampleReportUrl = state => selectSliceData(state).sampleReportUrl
const selectSampleReportUrlError = state => selectSliceData(state).sampleReportUrlError
const selectUserEmailSaved = state => selectSliceData(state).emailSaved
const selectUserEmailSavedError = state => selectSliceData(state).emailSavedError
const selectSeasonCSV = state => selectSliceData(state).seasonCSV
const selectSeasonCSVError = state => selectSliceData(state).seasonCSVError

export {
  selectPage,
  selectInnerPage,
  selectResponses,
  selectStepsProgress,
  selectPercentageCompleted,
  selectResults,
  selectIsSaving,
  selectChartData,
  selectChartDataError,
  selectProfile,
  selectReportUrl,
  selectResultsLoading,
  selectCalculatingResults,
  selectSaveError,
  selectResponsesError,
  selectLatestResults,
  selectIsLGScreen,
  selectIsXSScreen,
  selectIsMDScreen,
  selectReadings,
  selectUnansweredProfileQuestions,
  selectResultsClean,
  selectUserReportError,
  selectUserReportUrl,
  selectSignedUrlForReport,
  selectSignedUrlForReportError,
  selectPDFDownloading,
  selectResultsUpdateSuccess,
  selectSampleReportUrl,
  selectSampleReportUrlError,
  selectUserEmailSaved,
  selectUserEmailSavedError,
  selectSeasonCSV,
  selectSeasonCSVError
}
