import { StyleSheet } from '@react-pdf/renderer'
const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '50%'
  },
  logo: {
    filter: 'brightness(0) invert(1)',
    transform: 'scale(0.6)',
    marginLeft: 50
  }
})
export default styles
